import { api } from "../apiSlice";
import { baseUrl } from "../../configs";
import { globalQueryErrorHandler } from "../utils/errorHandlerUtils";

export interface Shop {
  id: string;
  name: string;
  region: string;
  seller_type: string;
  cipher: string;
  code: string;
}

interface GetAuthorizedShopsArgs {
  authCode: string;
}

interface GetAuthorizedShopsResponse {
  errMsg?: string;
  code?: number;
  shops: Shop[] | null;
}

interface ConnectTikTokShopArgs {
  shop: Shop;
}

interface ConnectTikTokShopResponse {
  errMsg?: string;
  code?: number;
  store?: {
    marketplaceType: string;
    marketplaceSubtype: string;
    mid: string;
    countryCode: string;
    shopName: string;
  };
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    getAuthorizedShops: build.mutation<
      GetAuthorizedShopsResponse,
      GetAuthorizedShopsArgs
    >({
      query: ({ authCode }) => {
        return {
          url: `${baseUrl}/auth/tiktokShop/shops`,
          method: "POST",
          data: { authCode },
        };
      },
      onQueryStarted: globalQueryErrorHandler("Tiktok shop connect", true),
    }),

    connectTikTokShop: build.mutation<
      ConnectTikTokShopResponse,
      ConnectTikTokShopArgs
    >({
      query: (data) => {
        return {
          url: `${baseUrl}/auth/tiktokShop/connect`,
          method: "POST",
          data,
        };
      },
      onQueryStarted: globalQueryErrorHandler("Tiktok shop connect", true),
    }),
  }),
});

export const { useGetAuthorizedShopsMutation, useConnectTikTokShopMutation } =
  extendedApiSlice;

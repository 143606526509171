import { Box, Grid, Input, Typography } from "@material-ui/core";

import { MenuItem } from "@material-ui/core";
import React from "react";
import ToolbarSelect from "~/components/select/toolbarSelect";
import styled from "styled-components";
import { useGetVendorCodesQuery } from "~/store/mystore/vendorProfitability.redux";
import { useTranslation } from "react-i18next";

type VendorCodeFieldProps = {
  vendorCode: string;
  setVendorCode: (vendorCode: string) => void;
  mid: string;
  marketplaceType: string;
  marketplaceSubtype: string;
};

const VendorCodeMenuItem = styled(MenuItem)`
  width: ${({ width }: { width?: number | null }) => width}px;
`;

const VendorCodeField: React.FC<VendorCodeFieldProps> = ({
  vendorCode,
  setVendorCode,
  mid,
  marketplaceType,
  marketplaceSubtype,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [menuWidth, setMenuWidth] = React.useState(
    Boolean(anchorEl) ? anchorEl?.offsetWidth : null
  );

  const selectVendorCode = (vendorCode: string) => {
    setAnchorEl(null);
    setVendorCode(vendorCode);
  };

  const { isLoading, vendorCodes } = useGetVendorCodesQuery(
    {
      mid,
      marketplaceType,
      marketplaceSubtype,
    },
    {
      selectFromResult: ({ data, isFetching }) => {
        return {
          isLoading: isFetching,
          vendorCodes: data?.vendorCodes || [],
        };
      },
    }
  );
  const renderOptions = () => {
    return vendorCodes.map((option) => (
      <VendorCodeMenuItem
        key={option}
        width={menuWidth}
        onClick={() => selectVendorCode(option)}
      >
        {option}
      </VendorCodeMenuItem>
    ));
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <Typography variant="body1">
          {t("profitability.VendorCodeInputLabel")}:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box mt={2}>
          <Input
            type="text"
            name="vendorCode"
            value={vendorCode}
            onChange={(e) => {
              setVendorCode(e.target.value);
            }}
            fullWidth
          />
        </Box>
        {!isLoading && (
          <Box mt={2}>
            <ToolbarSelect
              id="vendor-code-select"
              title=""
              anchorEl={anchorEl}
              fullWidth
              setAnchorEl={setAnchorEl}
              setMenuWidth={setMenuWidth}
              renderOptions={renderOptions}
              displayComponent={
                <Typography variant="body2" color="textPrimary">
                  Vendor Code
                </Typography>
              }
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default VendorCodeField;

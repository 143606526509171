import React, { useEffect } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";
import { marketplaceConstants } from "mm-mercado-libre-common/dist/shared/marketplaceConstants";

const mercadoLibreKey: Record<string, string> = {
  MERCADO_LIBRE_CLIENT_ID: String(process.env["MERCADO_LIBRE_CLIENT_ID"]),
  MERCADO_LIBRE_CBT_CLIENT_ID: String(
    process.env["MERCADO_LIBRE_CBT_CLIENT_ID"]
  ),
  MERCADO_LIBRE_MLB_CLIENT_ID: String(
    process.env["MERCADO_LIBRE_MLB_CLIENT_ID"]
  ),
};

const generateMercadoLibreAuthUrl = (countryCode: string) => {
  const { clientIdVar } = marketplaceConstants[countryCode];

  const clientId = mercadoLibreKey[clientIdVar];

  if (!clientId) {
    throw new Error("Error authorising MercadoLibre connection");
  }

  const authUrlSearchParams = new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    redirect_uri: "https://mm.merchantspring.io/callback/mercadolibre",
  });

  return `https://${
    marketplaceConstants[countryCode].authEndpoint
  }/authorization?${authUrlSearchParams.toString()}`;
};

export default function (onCode: (code: string) => void, countryCode: string) {
  const childWindow = React.useRef<Window | null>(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "mercadolibre"
      ) {
        const { params } = event.data;
        closeChildWindow();
        onCode(params.code);
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        generateMercadoLibreAuthUrl(countryCode),
        "authPopup-mm-mercadolibre",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=599,height=600,top=240,left=660.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}

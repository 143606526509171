import React, { ReactChild } from "react";

import RaisedButton from "~/components/buttons/raisedButton";
import { connectMercadoLibre } from "~/store/connections/connectMercadoLibre.redux";
import styled from "styled-components";
import { useAppDispatch } from "~/store/configureStore";
import useMercadoLibreAuthcodeAuth from "~/hooks/useMercadoLibreAuthcodeAuth";

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

interface MercadoLibreAuthoriseProps {
  onSuccess: () => void;
  onError: () => void;
  disabled: boolean;
  children: ReactChild;
  shopName: string;
  countryCode: string;
}

const MercadoLibreAuthorise: React.FC<MercadoLibreAuthoriseProps> = (props) => {
  const { children, onSuccess, onError, shopName, countryCode } = props;
  const dispatch = useAppDispatch();
  const onCode = React.useCallback(
    (code) => {
      dispatch(connectMercadoLibre(code, shopName, countryCode))
        .then(() => {
          onSuccess();
        })
        .catch((e: any) => {
          if (onError) {
            onError();
          } else {
            throw e;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, shopName, countryCode]
  );
  const triggerSignin = useMercadoLibreAuthcodeAuth(onCode, countryCode);
  const handleSignin = () => {
    triggerSignin();
  };

  return (
    <RaisedButton color="primary" onClick={handleSignin} {...props}>
      <StyledWrapper>
        <StyledChildren>{children}</StyledChildren>
      </StyledWrapper>
    </RaisedButton>
  );
};

export default MercadoLibreAuthorise;

import { Box, FormControlLabel } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useMemo, useState } from "react";
import {
  SettingsMenuItem,
  includeTaxSwitch,
} from "~/components/appSettings/menu";
import ShippedRevenueTrend, {
  CHART_TYPE,
  ChartOption,
} from "./shippedRevenueTrend";

import ShippedRevenueMetricSelect from "~/modules/vendor/shippedRevenueMetricSelect";
import get from "lodash/get";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const DISPLAY_CHART = {
  Bar: "Bar Chart",
  Line: "Line Chart",
};

const ShippedRevenueTrendWithTotals = memo(
  function ShippedRevenueTrendWithTotals() {
    const { t } = useTranslation();
    const userInfo = useTypedSelector((state) => state.user);

    const CHART_OPTIONS: ChartOption[] = useMemo(
      () => [
        {
          value: CHART_TYPE.Revenue,
          label: t("shippedRevenue.revenueOption"),
        },
        {
          value: CHART_TYPE.COGS,
          label: t("shippedRevenue.cogsOption"),
        },
        {
          value: CHART_TYPE.Units,
          label: t("shippedRevenue.unitsOption"),
        },
        {
          value: CHART_TYPE.NetPureProductMargin,
          label: t("shippedRevenue.netPureProductMarginOption"),
        },
      ],
      []
    );

    const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);
    const [lineChart, setLineChart] = useState<boolean>(false);

    const currentViewPreference = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.viewPreference") ||
        "sourcing"
    );

    const currentPeriod = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentPeriod") ||
        DATETIME_PERIODS.LAST30
    );

    const selectedTimezone = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.timezone") ||
        moment.tz.guess()
    );
    const currentCompare = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCompare") ||
        COMPARISON_PERIOD.THISYEAR
    );

    const currentRange = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentRange") ||
        getDatesFromPeriod(
          currentPeriod,
          currentCompare || COMPARISON_PERIOD.THISYEAR,
          selectedTimezone
        )
    );

    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    const chartToggle: SettingsMenuItem = {
      title: "",
      checked: lineChart,
      setChecked: (newValue: boolean) => setLineChart(newValue),
      tooltip: "",
    };

    const currentFilter = useTypedSelector(
      (state) =>
        state?.persistentAppSettings?.setting?.data?.currentFilter ||
        DEFAULT_FILTER
    );

    const chartActions = (
      <>
        <ShippedRevenueMetricSelect
          {...{
            width: "165px",
            selected: chartType,
            setSelected: setChartType,
          }}
        />
        <Box pl="12px">
          <FormControlLabel
            control={includeTaxSwitch(chartToggle)}
            label={chartToggle.checked ? DISPLAY_CHART.Line : DISPLAY_CHART.Bar}
          />
        </Box>
      </>
    );

    return (
      <ShippedRevenueTrend
        userInfo={userInfo}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        currentPeriod={currentPeriod}
        chartType={chartType}
        currentFilter={currentFilter}
        actions={chartActions}
        view={currentViewPreference}
        condensed={true}
        timezone={selectedTimezone}
        isLineChart={lineChart}
        displayTotals
      />
    );
  }
);

export default ShippedRevenueTrendWithTotals;

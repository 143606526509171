import React, { useEffect } from "react";

import { EVENT_ORIGIN_PATTERN } from "~/modules/login/authCallbackPage";

const serviceId = process.env.TIKTOK_SHOP_SERVICE_ID;

export default function (
  isUSCountry: boolean,
  onCode: (code: string) => void,
  onError: (e: string) => void
) {
  const authUrl = `https://services.${
    isUSCountry ? "us." : ""
  }tiktokshop.com/open/authorize?service_id=${serviceId}`;

  const childWindow = React.useRef<Window | null>(null);
  const closeChildWindow = () => {
    if (childWindow.current) {
      childWindow.current.close();
      childWindow.current = null;
    }
  };

  useEffect(() => {
    const messageListener: (this: Window, event: MessageEvent<any>) => void = (
      event
    ) => {
      if (
        EVENT_ORIGIN_PATTERN.test(event.origin) &&
        event.data.authProvider === "tiktokshop"
      ) {
        const { params } = event.data;
        closeChildWindow();

        const code = params.auth_code || params.code;
        if (code) {
          onCode(code);
          return;
        }

        onError("Invalid response from TikTok Shop");
      }
    };
    window.addEventListener("message", messageListener);
    return () => {
      closeChildWindow();
      window.removeEventListener("message", messageListener);
    };
  }, [onCode, onError]);

  const triggerSignin = () => {
    if (!childWindow.current || childWindow.current.closed) {
      childWindow.current = window.open(
        authUrl,
        "authPopup-mm-tiktokShop",
        "toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=999,height=600,top=140,left=160.5"
      );
    }
    if (childWindow.current) {
      childWindow.current.focus();
    }
  };
  return triggerSignin;
}

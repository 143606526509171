import { Box, FormControlLabel } from "@material-ui/core";
import OrderedRevenueTrend, {
  CHART_TYPE,
  ChartOption,
} from "~/modules/vendor/orderedRevenueTrend";
import React, { memo, useMemo, useState } from "react";
import {
  SettingsMenuItem,
  includeTaxSwitch,
} from "~/components/appSettings/menu";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import OrderedRevenueTable from "~/modules/vendor/orderedRevenueTable";
import OrderedRevenueTotals from "~/modules/vendor/orderedRevenueTotals";
import PageBlock from "~/components/containers/sideNavPageBlock";
import SearchFilter from "~/components/adTable/searchFilter";
import TableFilter from "~/components/panel/panelActions/tableFilter";
import get from "lodash/get";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const PAGE_SIZE = 20;

const DISPLAY_CHART = {
  Bar: "Bar Chart",
  Line: "Line Chart",
};

interface OrderedRevenueProps {
  singleWidget?: "table" | "summary" | "trend";
}

const OrderedRevenue = memo<OrderedRevenueProps>(function OrderedRevenue({
  singleWidget,
}) {
  const { t } = useTranslation();
  const CHART_OPTIONS: ChartOption[] = useMemo(
    () => [
      {
        value: CHART_TYPE.Revenue,
        label: t("orderedRevenue.revenueOption"),
      },
      {
        value: CHART_TYPE.GlanceViews,
        label: t("orderedRevenue.glanceViewsOption"),
      },
      {
        value: CHART_TYPE.Conversion,
        label: t("orderedRevenue.unitsPerViewOption"),
      },
    ],
    []
  );

  const userInfo = useTypedSelector((state) => state.user);
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );

  const { currentRange, currentCompare, currentPeriod, selectedTimezone } =
    useDateRangeFilters();

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const [searchText, setSearchText] = useState("");
  const [chartType, setChartType] = useState<ChartOption>(CHART_OPTIONS[0]);
  const [lineChart, setLineChart] = useState<boolean>(false);

  const switchChartType = (value: string) => {
    const option = CHART_OPTIONS.find(
      (chartOption) => chartOption.value === value
    );
    if (option) {
      setChartType(option);
    }
  };

  const chartToggle: SettingsMenuItem = {
    title: "",
    checked: lineChart,
    setChecked: (newValue: boolean) => setLineChart(newValue),
    tooltip: "",
  };

  const chartActions = (
    <>
      <TableFilter
        {...{
          width: "165px",
          options: CHART_OPTIONS,
          currentValue: chartType.label,
          handleChange: switchChartType,
        }}
      />
      <Box pl="12px">
        <FormControlLabel
          control={includeTaxSwitch(chartToggle)}
          label={chartToggle.checked ? DISPLAY_CHART.Line : DISPLAY_CHART.Bar}
        />
      </Box>
    </>
  );

  if (!store) {
    return (
      <PageBlock>
        <LoadingIndicator />
      </PageBlock>
    );
  }

  const tableActions = (
    <>
      <SearchFilter setSearchText={setSearchText} />
      <DownloadCsv
        {...{
          reportType: "orderedRevenue",
          path: "/api/generic/vendor/orderedRevenue",
          mid: store.merchantId,
          params: {
            fromDate: currentRange.fromDate,
            priorFromDate: currentRange.priorFromDate,
            toDate: currentRange.toDate,
            priorToDate: currentRange.priorToDate,
            timezone: currentRange.timezone,
            sortKey: "ordered_revenue",
            sortOrder: "desc",
            searchText,
            currentCurrency,
            exchangeRate: getExchangeRate(
              currencyRates,
              getCurrencyByCountryCode[store.marketplaceCountry],
              currentCurrency
            ),
          },
        }}
      />
    </>
  );

  if (singleWidget) {
    switch (singleWidget) {
      case "trend":
        return (
          <OrderedRevenueTrend
            userInfo={userInfo}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            chartType={chartType}
            actions={chartActions}
            condensed={true}
            timezone={selectedTimezone}
            isLineChart={lineChart}
          />
        );
      case "summary":
        return (
          <OrderedRevenueTotals
            userInfo={userInfo}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentCompare={currentCompare}
          />
        );
      case "table":
        return (
          <OrderedRevenueTable
            user={userInfo}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            searchText={searchText}
            pageSize={5}
            condensed={true}
            timezone={selectedTimezone}
          />
        );
      default:
        return (
          <OrderedRevenueTable
            user={userInfo}
            mid={store.merchantId}
            currentRange={currentRange}
            currentCurrency={currentCurrency}
            currentPeriod={currentPeriod}
            searchText={searchText}
            pageSize={5}
            condensed={true}
            timezone={selectedTimezone}
          />
        );
    }
  } else {
    return (
      <PageBlock>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <OrderedRevenueTrend
              userInfo={userInfo}
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              chartType={chartType}
              actions={chartActions}
              timezone={selectedTimezone}
              isLineChart={lineChart}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <OrderedRevenueTotals
              userInfo={userInfo}
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentCompare={currentCompare}
            />
          </Grid>
          <Grid item xs={12}>
            <OrderedRevenueTable
              user={userInfo}
              mid={store.merchantId}
              currentRange={currentRange}
              currentCurrency={currentCurrency}
              currentPeriod={currentPeriod}
              searchText={searchText}
              actions={tableActions}
              pageSize={PAGE_SIZE}
              timezone={selectedTimezone}
            />
          </Grid>
        </Grid>
      </PageBlock>
    );
  }
});

export default OrderedRevenue;

import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import ProductCogsSyncDialog, {
  SingleProductCogsVendor,
} from "~/modules/profitLossProductTable/productCogsSyncDialog";
import {
  ProductProfitabilityRow,
  useUpdateProductDirectCostsMutation,
} from "~/store/mystore/vendorProfitability.redux";
import React, { memo, useState } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import ProfitabilityProduct from "~/modules/widgets/vendorProfitability/profitabilityProduct";
import { SellTypeToggle } from "~/modules/widgets/vendorProfitability/sellTypeToggle";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export enum SellType {
  SELL_OUT = "SELL_OUT",
  SELL_IN = "SELL_IN",
}

const ProfitabilityVendorProduct = memo(function ProfitabilityVendorProduct() {
  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const savedSellType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorSellType ||
      SellType.SELL_OUT
  );
  const currentPeriod = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentPeriod") ||
      DATETIME_PERIODS.LAST30
  );
  const selectedTimezone = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.timezone") ||
      moment.tz.guess()
  );
  const currentCompare = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCompare") ||
      COMPARISON_PERIOD.THISYEAR
  );
  const currentRange = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentRange") ||
      getDatesFromPeriod(
        currentPeriod,
        currentCompare || COMPARISON_PERIOD.THISYEAR,
        selectedTimezone
      )
  );
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );

  const { t } = useTranslation();

  const [sellType, setSellType] = useState(savedSellType);
  const [product, setProduct] = useState<ProductProfitabilityRow>();
  const [productCogsDialogOpen, setProductCogsDialogOpen] = useState(false);
  const [updateProductDirectCosts] = useUpdateProductDirectCostsMutation();

  const handleCogsEdit = (product: ProductProfitabilityRow) => {
    setProduct(product);
    setProductCogsDialogOpen(true);
  };

  const handleProductCogsConfirm = (
    {
      mid,
      marketplaceType,
      marketplaceSubtype,
    }: { mid: string; marketplaceType: string; marketplaceSubtype: string },
    fromDate: string | undefined,
    cogs: SingleProductCogsVendor
  ) => {
    if (currentStore && fromDate && cogs) {
      updateProductDirectCosts({
        mid,
        marketplaceType,
        marketplaceSubtype,
        fromDate: moment(fromDate).unix(),
        sellType,
        directCost: {
          productSku: cogs.sku,
          eventCurrency: cogs.currency,
          eventValue: cogs.value,
          vendorCode: cogs?.vendorCode,
        },
        successMessage: t("vendorProfitability.productUploadSuccessMessage", {
          asin: cogs.sku,
        }),
      });
      setProductCogsDialogOpen(false);
    }
  };

  const storeCurrency =
    (currentStore
      ? getCurrencyByCountryCode[currentStore.marketplaceCountry]
      : null) ?? currentCurrency;

  return (
    <PageBlock>
      {currentStore ? (
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            alignItems="center"
          >
            <SellTypeToggle sellType={sellType} setSellType={setSellType} />
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <ProfitabilityProduct
              includeTax={includeTax}
              currentCurrency={currentCurrency}
              currentRange={currentRange}
              store={currentStore}
              sellType={sellType}
              handleCogsEdit={handleCogsEdit}
            />
            <ProductCogsSyncDialog
              store={currentStore}
              storeCurrency={storeCurrency}
              timezone={currentRange.timezone}
              open={productCogsDialogOpen}
              vendorSellType={sellType}
              product={
                product
                  ? { ...product, sellerSku: "", productSku: product.asin }
                  : undefined
              }
              onClose={() => {
                setProductCogsDialogOpen(false);
              }}
              onConfirm={handleProductCogsConfirm}
            />
          </Grid>
        </Grid>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default ProfitabilityVendorProduct;

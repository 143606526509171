import { Filter, Range } from "~/typedef/store";

import { PaginationArgs } from "~/typedef/pagination";
import { api } from "@store/apiSlice";
import { baseUrl } from "~/configs";
import { globalQueryErrorHandler } from "@store/utils/errorHandlerUtils";

interface CrossChannelProductRequestArgs {
  currentRange: Range;
  paginationParams: PaginationArgs;
  currentFilter: Filter;
}

export interface CrossChannelProduct {
  title: string;
  imageUrl: string;
  linkUrl: string;
  sellerSku: string;
  totalSales: number;
  totalQuantity: number;
}

interface CrossChannelProductResponseData {
  products: CrossChannelProduct[];
  count: number;
}

const extendedApiSlice = api.injectEndpoints({
  endpoints: (build) => ({
    crossChannelProduct: build.query<
      CrossChannelProductResponseData,
      CrossChannelProductRequestArgs
    >({
      query: (params) => {
        return {
          url: `${baseUrl}/api/generic-mws-service/api/crossChannel/products`,
          method: "POST",
          data: params,
        };
      },
      onQueryStarted: globalQueryErrorHandler("CrossChannelProduct"),
    }),
  }),
});

export const { useCrossChannelProductQuery } = extendedApiSlice;

import { Box, Divider, Grid, Typography } from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  getDatesFromPeriod,
} from "../../../store/utils/dateTimeUtils";
import {
  COMPARISON_PERIOD_LABELS,
  DATETIME_PERIOD_LABELS,
} from "../../../store/persistentAppSettings.redux";
import {
  LanguageOutlined,
  ScheduleOutlined,
  TrendingUpOutlined,
} from "@material-ui/icons";
import React, { memo } from "react";

import { ApplyProps } from "~/components/dateRangePicker/fullDateTimeDropdown";
import { DateRange } from "~/typedef/date";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Label = styled(Typography)`
  text-transform: uppercase;
`;

const TimezoneIcon = styled(LanguageOutlined)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

const TimeIcon = styled(ScheduleOutlined)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

const ComparisonIcon = styled(TrendingUpOutlined)`
  height: 16px;
  width: 16px;
  margin-right: 4px;
`;

const GreyDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.palette.border.main};
`;

interface TimePeriodDisplayProps {
  activeLink?: boolean;
  onApply?: (apply: ApplyProps) => void;
  period: DATETIME_PERIODS;
  restrictTimezone?: string[] | null;
  compare: COMPARISON_PERIOD;
  setMobileSelection?: (target: HTMLElement, period: DATETIME_PERIODS) => void;
  range: DateRange;
  timezone: string;
  useList?: boolean;
}

export const TimePeriodDisplay = memo<TimePeriodDisplayProps>(
  function TimePeriodDisplay({
    activeLink,
    period,
    range,
    compare,
    onApply,
    setMobileSelection,
    timezone,
    restrictTimezone,
    useList,
  }) {
    const { t } = useTranslation();
    const selectPeriod = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (activeLink && setMobileSelection && compare && onApply) {
        if (period === DATETIME_PERIODS.CUSTOM) {
          setMobileSelection(e.currentTarget, period);
        } else {
          const { fromDate, toDate, priorFromDate, priorToDate, interval } =
            getDatesFromPeriod(period, compare, timezone);
          onApply({
            timePeriod: period,
            comparisonPeriod: compare,
            startDate: fromDate,
            endDate: toDate,
            priorStartDate: priorFromDate,
            priorEndDate: priorToDate,
            interval,
            timezone,
          });
        }
      }
    };

    const getLabel = () => {
      if (
        period === DATETIME_PERIODS.CUSTOM &&
        !activeLink &&
        range.fromDate &&
        range.toDate
      ) {
        const fromDate = moment
          .unix(range.fromDate)
          .tz(timezone || moment.tz.guess())
          .tz(moment.tz.guess(), true);
        const toDate = moment
          .unix(range.toDate)
          .tz(timezone || moment.tz.guess())
          .tz(moment.tz.guess(), true);
        return `${fromDate.format("D MMM")} - ${toDate.format("D MMM")}`;
      } else {
        return t(DATETIME_PERIOD_LABELS[period]);
      }
    };

    const getComparisonLabel = () => {
      if (
        period === DATETIME_PERIODS.CUSTOM &&
        !activeLink &&
        range.priorFromDate &&
        range.priorToDate
      ) {
        const fromDate = moment
          .unix(range.priorFromDate)
          .tz(timezone || moment.tz.guess())
          .tz(moment.tz.guess(), true);
        const toDate = moment
          .unix(range.priorToDate)
          .tz(timezone || moment.tz.guess())
          .tz(moment.tz.guess(), true);
        return `${fromDate.format("D MMM")} - ${toDate.format("D MMM")}`;
      } else {
        return t(COMPARISON_PERIOD_LABELS[compare]);
      }
    };

    return (
      <Grid
        container
        id="date-range-display"
        alignItems="stretch"
        justifyContent="space-between"
        onClick={selectPeriod}
      >
        {(!restrictTimezone || Boolean(restrictTimezone?.length)) &&
          !activeLink &&
          !useList && (
            <>
              <Grid item>
                <Box display="flex" alignItems="center" pr={0.5}>
                  <TimezoneIcon />
                  <Label variant="body2" color="textPrimary">
                    {moment.tz(timezone).format("z")}
                  </Label>
                </Box>
              </Grid>
              <GreyDivider orientation="vertical" flexItem={true} />
            </>
          )}
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            pl={activeLink ? 1 : 0.5}
            pr={activeLink ? 0 : 0.5}
            height="100%"
          >
            {!activeLink && <TimeIcon />}
            <Label variant="body2" color="textPrimary">
              {getLabel()}
            </Label>
          </Box>
        </Grid>
        {!activeLink && !useList && (
          <>
            <GreyDivider orientation="vertical" flexItem={true} />
            <Grid item>
              <Box display="flex" alignItems="center" pl={0.5}>
                <ComparisonIcon />
                <Label variant="body2" color="textPrimary">
                  {getComparisonLabel()}
                </Label>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
);

export default TimePeriodDisplay;

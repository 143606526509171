import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import {
  Box,
  FormControl,
  Input,
  Menu,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  COMPARISON_PERIOD,
  DATETIME_PERIODS,
  INTERVAL,
  RESTRICTED_CHART_INTERVALS,
  getDatesFromPeriod,
} from "~/store/utils/dateTimeUtils";
import {
  DateRangePicker,
  DefinedRangeProps,
  Preview,
  RangeKeyDict,
  StaticRange,
  createStaticRanges,
} from "react-date-range";
import React, { useEffect, useMemo, useState } from "react";
import { ScheduleOutlined, TrendingUpOutlined } from "@material-ui/icons";

import { ApplyProps } from "./fullDateTimeDropdown";
import ComparisonPeriodSelect from "~/components/select/comparisonPeriodSelect";
import { DATETIME_PERIOD_LABELS } from "../../store/persistentAppSettings.redux";
import { DateRange } from "~/typedef/date";
import IntervalInput from "./intervalInput";
import SmallButton from "../buttons/smallButton";
import TimezoneSelect from "../toolbars/toolbarComponents/timezoneDropdown";
import get from "lodash/get";
import moment from "moment-timezone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const TimeIcon = styled(ScheduleOutlined)`
  height: 20px;
  display: flex;
  margin-left: -5px;
`;

const ComparisonIcon = styled(TrendingUpOutlined)`
  height: 20px;
  display: flex;
  margin-left: -5px;
`;

const DateRangePickerCustom = styled(DateRangePicker)`
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  & .rdrDefinedRangesWrapper {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.palette.text.primary};
    width: 170px;
    border-right: solid 1px ${({ theme }) => theme.palette.border.main};
    background: ${({ theme }) => theme.palette.background.paper};
    & .rdrStaticRanges {
      display: flex;
      height: 100%;
      font-size: 0.7rem;
      & .rdrStaticRange {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        height: 30px;
        border-bottom: solid 1px ${({ theme }) => theme.palette.border.main};
        background: ${({ theme }) => theme.palette.background.paper};
        color: ${({ theme }) => theme.palette.text.primary};
      }
      & .rdrStaticRange:hover {
        background-color: ${({ theme }) => theme.palette.primary.light};
      }
      & .rdrStaticRange:hover .rdrStaticRangeLabel,
      .rdrStaticRange:focus .rdrStaticRangeLabel {
        background: none;
      }
      & .rdrStaticRangeSelected {
        background-color: ${({ theme }) => theme.palette.primary.light};
        color: ${({ theme }) => theme.palette.primary.contrastText};
        border-left: ${({ theme }) =>
          `5px solid ${theme.palette.primary.main}`};
        & .rdrStaticRangeLabel {
          color: ${({ theme }) => theme.palette.primary.contrastText};
        }
      }
    }
    & .rdrInputRanges {
      display: none;
    }
  }
  & .rdrCalendarWrapper {
    display: flex;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.background.paper};
    & .rdrMonthAndYearWrapper {
      height: auto;
      display: flex;
      font-size: 1rem;
      & .rdrMonthAndYearPickers select {
        color: ${({ theme }) => theme.palette.text.primary};
        option {
          background: ${({ theme }) => theme.palette.common.white};
        }
      }
      & .rdrNextPrevButton {
        background: ${({ theme }) => theme.palette.background.default};
      }
      & .rdrNextButton i {
        border-color: transparent transparent transparent
          ${({ theme }) => theme.palette.common.black};
      }
      & .rdrPrevButton i {
        border-color: transparent ${({ theme }) => theme.palette.common.black}
          transparent transparent;
      }
    }
    & .rdrMonths {
      padding-top: 0.5rem;
    }
    & .rdrMonth {
      padding-bottom: 5px;
      width: 15rem;
    }
    & .rdrMonthName {
      text-align: center;
      color: ${({ theme }) => theme.palette.text.secondary};
      font-size: 0.85rem;
    }
    & .rdrWeekDays {
      font-size: 0.8rem;
      padding-bottom: 0.5rem;
    }
    & .rdrWeekDay {
      line-height: 1.66em;
    }
    & .rdrDays {
      & .rdrDay {
        height: 2.2rem;
        line-height: 2rem;
        & .rdrSelected,
        .rdrInRange,
        .rdrStartEdge,
        .rdrEndEdge {
          top: 0;
          bottom: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      & .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
      .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
      .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
      .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
      & .rdrDayToday .rdrDayNumber span:after {
        background: ${({ theme }) => theme.palette.primary.main};
      }
      & .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
      .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
      .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
      .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
        background: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }
    & .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayInPreview {
      top: 0;
      bottom: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const SmallInput = styled(Input)`
  width: inherit;
  font-size: 0.75rem;
  ${({ theme }) => (theme.darkModeEnabled ? `color-scheme: dark;` : null)}
`;

const ButtonWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const Section = styled(Box)`
  border-right: 1px solid ${({ theme }) => theme.palette.border.main};
`;

const monthsAgo = (months: number) =>
  moment().startOf("day").subtract(months, "months").toDate();

/** For longer chart intervals, we only support a fixed
 * timezone due to limitations with database continuous aggregates.
 * This function returns true iff the input interval is one
 * of the intervals that only supports a fixed timezone.*/
export const shouldLimitTimezones = (chartInterval?: INTERVAL) => {
  return RESTRICTED_CHART_INTERVALS.includes(chartInterval);
};

/** Timezones allowed in restricted mode due to database constraints.
 * See filterTimezones function.
 */
export const RESTRICTED_TIMEZONE_WHITELIST = ["GMT", "America/Los_Angeles"];

/** For longer chart intervals, we only support a fixed
 * timezone due to limitations with database continuous aggregates.
 * This function can be passed to filter the timezones
 * in the timezone selector to the ones we support */
export const filterTimezones = (timezone: string, chartInterval?: INTERVAL) => {
  if (RESTRICTED_CHART_INTERVALS.includes(chartInterval)) {
    return RESTRICTED_TIMEZONE_WHITELIST.includes(timezone);
  } else {
    return true;
  }
};

const FromDateInput = ({
  date,
  changeDate,
}: {
  date: Date;
  changeDate: (value: string, field: string) => void;
}) => (
  <SmallInput
    type="date"
    value={moment(date).format("YYYY-MM-DD")}
    onChange={(e) => changeDate(e.target.value, "start")}
  />
);

const ToDateInput = ({
  date,
  changeDate,
}: {
  date: Date;
  changeDate: (value: string, field: string) => void;
}) => (
  <SmallInput
    type="date"
    value={moment(date).format("YYYY-MM-DD")}
    onChange={(e) => changeDate(e.target.value, "end")}
  />
);

const PriorFromDateInput = ({
  date,
  changeDate,
}: {
  date: Date;
  changeDate: (value: string, field: string) => void;
}) => (
  <SmallInput
    type="date"
    value={moment(date).format("YYYY-MM-DD")}
    onChange={(e) => changeDate(e.target.value, "priorStart")}
  />
);

const PriorToDateInput = ({
  date,
  changeDate,
}: {
  date: Date;
  changeDate: (value: string, field: string) => void;
}) => (
  <SmallInput
    type="date"
    value={moment(date).format("YYYY-MM-DD")}
    onChange={(e) => changeDate(e.target.value, "priorEnd")}
  />
);

const CondensedDateSelection = ({
  timezone,
  setTimezone,
  period,
  comparisonPeriod,
  changeComparisonPeriod,
  changeDate,
  startDate,
  endDate,
  priorStartDate,
  priorEndDate,
  chartInterval,
  setChartInterval,
  restrictTimezone,
}: {
  timezone: string;
  setTimezone: (t: string) => void;
  period: DATETIME_PERIODS;
  comparisonPeriod: COMPARISON_PERIOD;
  changeComparisonPeriod: (c: COMPARISON_PERIOD) => void;
  changeDate: (value: string, field: string) => void;
  startDate: Date;
  endDate: Date;
  priorStartDate: Date;
  priorEndDate: Date;
  chartInterval: INTERVAL;
  setChartInterval: (i: INTERVAL) => void;
  restrictTimezone?: string[] | null;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {(!restrictTimezone || Boolean(restrictTimezone?.length)) && (
        <Box display="flex" flexDirection="column" width={"100%"}>
          <TimezoneSelect
            handleSelect={setTimezone}
            selectedTimezone={timezone}
            filterTimezones={
              restrictTimezone?.length
                ? (timezone: string) => restrictTimezone.includes(timezone)
                : filterTimezones
            }
            interval={chartInterval}
            tooltip={
              shouldLimitTimezones(chartInterval)
                ? t("timezoneSelect.disabledInterval")
                : undefined
            }
          />
        </Box>
      )}
      <Box pt={2} display="flex" flexDirection="column" width="100%">
        <ComparisonPeriodSelect
          setCompare={changeComparisonPeriod}
          currentCompare={comparisonPeriod}
          width="100%"
        />
      </Box>
      <Box pt={2} display="flex" flexDirection="column">
        <Box display="flex">
          <TimeIcon />
          <Typography variant="body1">
            {t("createReport.selectDateRangeTitle")}:
          </Typography>
        </Box>
        <Box
          display="flex"
          pt={1}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box pr={1} display="flex" alignItems="center">
            <Typography variant="body2">{t("dateRange.from")}: </Typography>
          </Box>
          <Box>
            <FormControl>
              <FromDateInput changeDate={changeDate} date={startDate} />
            </FormControl>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Box pr={1} display="flex" alignItems="center">
            <Typography variant="body2">{t("dateRange.to")}: </Typography>
          </Box>
          <Box>
            <FormControl>
              <ToDateInput changeDate={changeDate} date={endDate} />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box pt={2} display="flex" flexDirection="column">
        <Box display="flex">
          <ComparisonIcon />
          <Typography variant="body1">
            {t("clientReport.comparison")}
          </Typography>
        </Box>
        <Box
          display="flex"
          pt={1}
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Box pr={1} display="flex" alignItems="center">
            <Typography variant="body2">{t("dateRange.from")}: </Typography>
          </Box>
          <Box>
            <FormControl>
              <PriorFromDateInput
                changeDate={changeDate}
                date={priorStartDate}
              />
            </FormControl>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          justifyContent="space-between"
        >
          <Box pr={1} display="flex" alignItems="center">
            <Typography variant="body2">{t("dateRange.to")}: </Typography>
          </Box>
          <Box>
            <FormControl>
              <PriorToDateInput changeDate={changeDate} date={priorEndDate} />
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box
        pt={2}
        display="flex"
        flexDirection="column"
        width="100%"
        justifyContent={"space-between"}
      >
        <IntervalInput
          chartInterval={chartInterval}
          setChartInterval={setChartInterval}
          startDate={startDate}
          endDate={endDate}
          currentPeriod={period}
        />
      </Box>
    </>
  );
};

type CustomPreview = Preview & {
  key: DATETIME_PERIODS;
};
interface CustomStaticRange extends StaticRange {
  range: (props?: DefinedRangeProps) => CustomPreview;
}

interface DatePickerProps {
  onApply: (props: ApplyProps) => void;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  range: DateRange;
  period: DATETIME_PERIODS;
  compare: COMPARISON_PERIOD;
  restrictTimezone?: string[] | null;
  selectedInterval: INTERVAL;
  selectedTimezone?: string;
  preferredTimezone?: string;
}

const DatePicker = ({
  onApply,
  anchorEl,
  handleClose,
  range,
  period,
  compare,
  selectedInterval,
  selectedTimezone,
  restrictTimezone,
  preferredTimezone,
}: DatePickerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const [timezone, setTimezone] = useState(
    selectedTimezone || moment.tz.guess()
  );
  const [startDate, setStartDate] = useState(monthsAgo(1));
  const [endDate, setEndDate] = useState(moment().toDate());
  const [priorStartDate, setPriorStartDate] = useState(monthsAgo(2));
  const [priorEndDate, setPriorEndDate] = useState(monthsAgo(1));
  const [timePeriod, setTimePeriod] = useState<DATETIME_PERIODS>(
    DATETIME_PERIODS.LAST30
  );
  const [comparisonPeriod, setComparisonPeriod] = useState(
    COMPARISON_PERIOD.THISYEAR
  );
  const [chartInterval, setChartInterval] = useState<INTERVAL>(INTERVAL.DAYS);

  const [marginThreshold, setMarginThreshold] = useState(138);
  const minOrderDate = useTypedSelector((state) =>
    get(state, "overview.minDate")
  );
  const [minDate, setMinDate] = useState(
    minOrderDate
      ? moment
          .max(
            moment().subtract(2, "year").subtract(1, "day"),
            moment(minOrderDate)
          )
          .toDate()
      : moment().subtract(2, "year").subtract(1, "day").toDate()
  );

  useEffect(() => {
    setComparisonPeriod(compare);
  }, [compare]);

  useEffect(() => {
    setChartInterval(selectedInterval);
  }, [selectedInterval]);

  useEffect(() => {
    if (
      // If the user switches away from a monthly, quarterly etc interval,
      // restore a friendlier timezone than UTC
      timezone === "GMT" &&
      preferredTimezone
    ) {
      setTimezone(preferredTimezone);
    }
  }, [chartInterval]);

  useEffect(() => {
    setTimePeriod(period);
    if (range) {
      //Converting to local timezone keeping the timestamp for display
      const rangeStart = moment
        .unix(range.fromDate)
        .tz(selectedTimezone || moment.tz.guess())
        .tz(moment.tz.guess(), true);
      const rangeEnd = moment
        .unix(range.toDate)
        .tz(selectedTimezone || moment.tz.guess())
        .tz(moment.tz.guess(), true);

      setStartDate(rangeStart.toDate());
      setEndDate(rangeEnd.toDate());

      if (period === DATETIME_PERIODS.CUSTOM) {
        const priorFromDate = moment
          .unix(range.priorFromDate)
          .tz(selectedTimezone || moment.tz.guess())
          .tz(moment.tz.guess(), true);
        const priorToDate = moment
          .unix(range.priorToDate)
          .tz(selectedTimezone || moment.tz.guess())
          .tz(moment.tz.guess(), true);

        setPriorStartDate(priorFromDate.toDate());
        setPriorEndDate(priorToDate.toDate());
      } else {
        const { priorFromDate, priorToDate } = getDatesFromPeriod(
          period,
          comparisonPeriod,
          moment.tz.guess(),
          rangeStart.unix(),
          rangeEnd.unix(),
          range.priorFromDate,
          range.priorToDate
        );

        setPriorStartDate(moment.unix(priorFromDate).toDate());
        setPriorEndDate(moment.unix(priorToDate).toDate());
      }
    }
  }, [anchorEl, period, range, selectedTimezone]);

  const changeComparisonPeriod = (comparison: COMPARISON_PERIOD) => {
    const { priorFromDate, priorToDate } = getDatesFromPeriod(
      timePeriod,
      comparison,
      moment.tz.guess(),
      moment(startDate).unix(),
      moment(endDate).unix()
    );
    setPriorStartDate(moment.unix(priorFromDate).toDate());
    setPriorEndDate(moment.unix(priorToDate).toDate());
    setComparisonPeriod(comparison);
  };

  const staticRanges = useMemo(
    () =>
      createStaticRanges([
        {
          label: t(DATETIME_PERIOD_LABELS.TODAY),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.DAY,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.DAY,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.DAY;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.YESTERDAY),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.YESTERDAY,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.YESTERDAY,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.YESTERDAY;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.WTD),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.WEEK,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.WEEK,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.WEEK;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.WTDMTS),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.WEEKMTS,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.WEEKMTS,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.WEEKMTS;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.LAST30),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.LAST30,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.LAST30,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.LAST30;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.MTD),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.MTD,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.MTD,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.MTD;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.CURRENTMONTH),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.CURRENTMONTH,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.CURRENTMONTH,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.CURRENTMONTH;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.LASTMONTH),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.LASTMONTH,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.LASTMONTH,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.LASTMONTH;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.CURRENTQ),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.CURRENTQ,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.CURRENTQ,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.CURRENTQ;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.LASTQ),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.LASTQ,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.LASTQ,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.LASTQ;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.LAST12),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.LAST12,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.LAST12,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.LAST12;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.YTD),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.YEAR,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.YEAR,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.YEAR;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.CURRENTYEAR),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.CURRENTYEAR,
                comparisonPeriod,
                moment.tz.guess()
              );
            return {
              key: DATETIME_PERIODS.CURRENTYEAR,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.CURRENTYEAR;
          },
        },
        {
          label: t(DATETIME_PERIOD_LABELS.CUSTOM),
          range: () => {
            const { fromDate, toDate, priorFromDate, priorToDate } =
              getDatesFromPeriod(
                DATETIME_PERIODS.CUSTOM,
                comparisonPeriod,
                moment.tz.guess(),
                moment(startDate).unix(),
                moment(endDate).unix(),
                moment(priorStartDate).unix(),
                moment(priorEndDate).unix()
              );
            return {
              key: DATETIME_PERIODS.CUSTOM,
              startDate: moment.unix(fromDate).toDate(),
              endDate: moment.unix(toDate).toDate(),
              priorStartDate: moment.unix(priorFromDate).toDate(),
              priorEndDate: moment.unix(priorToDate).toDate(),
            };
          },
          isSelected() {
            return timePeriod === DATETIME_PERIODS.CUSTOM;
          },
        },
      ]),
    [comparisonPeriod, endDate, startDate, t, timePeriod]
  );

  const dashboardScreen = document.getElementById("dashboard-screen");

  useEffect(() => {
    if (dashboardScreen) {
      const offset = dashboardScreen.getBoundingClientRect().left;
      setMarginThreshold(offset);
    }
  }, [dashboardScreen]);

  const getMatchingPeriod = (
    start: Date | null,
    end: Date | null
  ): CustomStaticRange | undefined =>
    staticRanges.find((staticRange) => {
      const rangeObject = staticRange.range();
      if (
        moment(rangeObject.startDate).isSame(moment(start), "d") &&
        moment(rangeObject.endDate).isSame(moment(end), "d")
      ) {
        return true;
      }
      return false;
    }) as CustomStaticRange;

  const onDateSelection = (item: RangeKeyDict) => {
    const values =
      Object.values(item).length &&
      (Object.values(item)[0] as {
        startDate: Date;
        endDate: Date;
        priorStartDate: Date;
        priorEndDate: Date;
      });
    if (values) {
      const {
        startDate: from,
        endDate: to,
        priorStartDate: priorFrom,
        priorEndDate: priorTo,
      } = values;

      const priorStart = priorFrom ? priorFrom : item.prior ? from : null;
      const priorEnd = priorTo ? priorTo : item.prior ? to : null;
      const start = priorFrom ? from : item.current ? from : null;
      const end = priorTo ? to : item.current ? to : null;

      if (priorStart) {
        const inclusivePriorStartDate = moment(priorStart)
          .startOf("day")
          .toDate();
        setPriorStartDate(inclusivePriorStartDate);
      }
      if (priorEnd) {
        const inclusivePriorEndDate = moment(priorEnd).endOf("day").toDate();
        setPriorEndDate(inclusivePriorEndDate);
      }

      const matchingPeriod = getMatchingPeriod(start, end);
      if (matchingPeriod) {
        setTimePeriod(matchingPeriod.range().key);
      } else {
        setTimePeriod(DATETIME_PERIODS.CUSTOM);
      }
      if (start) {
        const inclusiveStartDate = moment(start).startOf("day").toDate();
        setStartDate(inclusiveStartDate);
      }
      if (end) {
        const inclusiveEndDate = moment(end).endOf("day").toDate();
        setEndDate(inclusiveEndDate);
        setMinDate(
          minOrderDate
            ? moment
                .max(
                  moment(inclusiveEndDate)
                    .subtract(2, "year")
                    .subtract(1, "day"),
                  moment(minOrderDate)
                )
                .toDate()
            : moment(inclusiveEndDate)
                .subtract(2, "year")
                .subtract(1, "day")
                .toDate()
        );
      }
    }
  };

  const applySelection = () => {
    const { interval } = getDatesFromPeriod(
      timePeriod,
      comparisonPeriod,
      timezone,
      moment(startDate).unix(),
      moment(endDate).unix(),
      moment(priorStartDate).unix(),
      moment(priorEndDate).unix()
    );
    onApply({
      timePeriod,
      comparisonPeriod,
      startDate: moment(startDate).parseZone().tz(timezone, true).unix(),
      endDate: moment(endDate).parseZone().tz(timezone, true).unix(),
      priorStartDate: moment(priorStartDate)
        .parseZone()
        .tz(timezone, true)
        .unix(),
      priorEndDate: moment(priorEndDate).parseZone().tz(timezone, true).unix(),
      interval: chartInterval,
      timezone,
    });
  };

  const changeDate = (date: string, type: string) => {
    if (date) {
      const newDate = moment(date);
      let matchingPeriod;
      switch (type) {
        case "start":
          const inclusiveStartDate = moment(newDate).startOf("day").toDate();
          setStartDate(inclusiveStartDate);
          matchingPeriod = getMatchingPeriod(inclusiveStartDate, endDate);
          if (matchingPeriod) {
            setTimePeriod(matchingPeriod.range().key);
          } else {
            setTimePeriod(DATETIME_PERIODS.CUSTOM);
          }
          break;
        case "end":
          const inclusiveEndDate = moment(newDate).endOf("day").toDate();
          setEndDate(inclusiveEndDate);
          matchingPeriod = getMatchingPeriod(startDate, inclusiveEndDate);
          if (matchingPeriod) {
            setTimePeriod(matchingPeriod.range().key);
          } else {
            setTimePeriod(DATETIME_PERIODS.CUSTOM);
          }
          break;
        case "priorStart":
          const inclusivePriorStartDate = moment(newDate)
            .startOf("day")
            .toDate();
          setPriorStartDate(inclusivePriorStartDate);
          setTimePeriod(DATETIME_PERIODS.CUSTOM);
          break;
        case "priorEnd":
          const inclusivePriorEndDate = moment(newDate).endOf("day").toDate();
          setPriorEndDate(inclusivePriorEndDate);
          setTimePeriod(DATETIME_PERIODS.CUSTOM);
          break;
        default:
          break;
      }
    }
  };

  const DesktopDateSelection = (
    <Menu
      id={`date-range-wrapper`}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: marginThreshold * -2,
      }}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      autoFocus={false}
      onClose={handleClose}
    >
      <Box display="flex">
        <Section display="flex" flexDirection="column" p={2}>
          <CondensedDateSelection
            timezone={timezone}
            setTimezone={setTimezone}
            period={timePeriod}
            comparisonPeriod={comparisonPeriod}
            changeComparisonPeriod={changeComparisonPeriod}
            changeDate={changeDate}
            startDate={startDate}
            priorStartDate={priorStartDate}
            endDate={endDate}
            priorEndDate={priorEndDate}
            chartInterval={chartInterval}
            setChartInterval={setChartInterval}
            restrictTimezone={restrictTimezone}
          />
        </Section>
        <DateRangePickerCustom
          theme={theme}
          onChange={onDateSelection}
          //showSelectionPreview={true}
          showMonthAndYearPickers={true}
          showDateDisplay={false}
          editableDateInputs={false}
          dateDisplayFormat="dd/MM/yyyy"
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[
            {
              startDate,
              endDate,
              key: "current",
              color: theme.palette.primary.main,
            },
            {
              startDate: priorStartDate,
              endDate: priorEndDate,
              key: "prior",
              color: theme.palette.secondary.main,
            },
          ]}
          staticRanges={staticRanges}
          inputRanges={[]}
          minDate={minDate}
          direction="horizontal"
          rangeColors={[
            theme.palette.primary.main,
            theme.palette.secondary.main,
          ]}
          preventSnapRefocus={true}
          calendarFocus="backwards"
        />
      </Box>
      <ButtonWrapper
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <SmallButton
          variant="contained"
          color="primary"
          onClick={applySelection}
        >
          {t("generic.applyButton")}
        </SmallButton>
      </ButtonWrapper>
    </Menu>
  );

  const MobileDateSelection = (
    <Menu
      id={`date-range-wrapper`}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      autoFocus={false}
      marginThreshold={5}
      onClose={handleClose}
    >
      <ButtonWrapper
        p={2}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        justifyContent="flex-end"
        width={250}
      >
        <CondensedDateSelection
          timezone={timezone}
          setTimezone={setTimezone}
          period={timePeriod}
          comparisonPeriod={comparisonPeriod}
          changeComparisonPeriod={changeComparisonPeriod}
          changeDate={changeDate}
          startDate={startDate}
          priorStartDate={priorStartDate}
          endDate={endDate}
          priorEndDate={priorEndDate}
          chartInterval={chartInterval}
          setChartInterval={setChartInterval}
          restrictTimezone={restrictTimezone}
        />

        <Box display="flex" pt={2} width="100%">
          <SmallButton
            fullWidth
            variant="contained"
            color="primary"
            onClick={applySelection}
          >
            {t("generic.applyButton")}
          </SmallButton>
        </Box>
      </ButtonWrapper>
    </Menu>
  );

  return smDown ? MobileDateSelection : DesktopDateSelection;
};

export default DatePicker;

import { Avatar, Box, Link, Typography, withTheme } from "@material-ui/core";
import React, { memo } from "react";

import Bold from "../components/typography/bold";
import { BuildRounded } from "@material-ui/icons";
import RaisedButton from "../components/buttons/raisedButton";
import SimpleToolbar from "~/components/toolbars/simpleToolbar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Background = styled(Box)`
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;

  & > #simple-toolbar {
    flex-basis: unset;
    flex-shrink: 0;
  }
`;

const MaintenancePageContent = styled(Box)`
  padding: 4rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 500px;
`;

const IconWrapper = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.info.dark};
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.75rem;
`;

const StyledTypography = styled(Typography)`
  line-height: 1.5;
`;

const MaintenancePage = memo(function MaintenancePage() {
  const { t } = useTranslation();

  return (
    <Background>
      <SimpleToolbar />
      <MaintenancePageContent>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <IconWrapper>
            <BuildRounded color="primary" fontSize="small" />
          </IconWrapper>
          <Bold variant="h1">{t("maintenancePage.mainTitle")}</Bold>
        </Box>
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <StyledTypography variant="h2" align="center">
            {t("maintenancePage.subtitle")}
          </StyledTypography>
        </Box>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <StyledTypography variant="body1" align="center">
            {t("maintenancePage.message")}
          </StyledTypography>
        </Box>
        <Box
          p={2}
          flexDirection="column"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box p={1}>
            <RaisedButton
              component={Link}
              href="https://status.merchantspring.io/"
              color="primary"
            >
              {t("maintenancePage.viewStatusPage")}
            </RaisedButton>
          </Box>
        </Box>
      </MaintenancePageContent>
    </Background>
  );
});

export default MaintenancePage;

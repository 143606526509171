import {
  CrossChannelProduct,
  useCrossChannelProductQuery,
} from "~/store/overview/crossChannelPerformance.redux";
import {
  DEFAULT_CURRENCY,
  DEFAULT_FILTER,
} from "~/store/persistentAppSettings.redux";
import React, { memo, useCallback, useMemo, useState } from "react";

import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import Panel from "~/components/panel/panel";
import Table from "~/components/adTable/table";
import { TableCellProp } from "~/components/table/cellProps";
import { ValueAndGrowthCell } from "~/components/table/cells/valueAndGrowthCell";
import { formatCurrencyRounded } from "~/utils/currencyUtils";
import get from "lodash/get";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const CrossChannelProductPerformance = memo(
  function CrossChannelProductPerformance() {
    const { t } = useTranslation();

    const currencyRates = useTypedSelector(
      (state) => state.globalVar.currencyRates
    );
    const currentCurrency = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentCurrency") ||
        DEFAULT_CURRENCY
    );

    const { currentRange } = useDateRangeFilters();

    const currentFilter = useTypedSelector(
      (state) =>
        get(state, "persistentAppSettings.setting.data.currentFilter") ||
        DEFAULT_FILTER
    );

    const [paginationParams, setPaginationParams] = useState({
      pageIndex: 0,
      pageSize: 5,
      sortKey: "totalSales",
      sortOrder: "desc",
    });

    const { products, totalCount, isLoading } = useCrossChannelProductQuery(
      {
        paginationParams,
        currentRange,
        currentFilter,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          products: data?.products || [],
          totalCount: data?.count || 0,
          isLoading: isFetching,
        }),
      }
    );

    const columns = useMemo(
      () => [
        {
          Header: t("crossChannelProduct.product"),
          id: "title",
          accessor: (row: CrossChannelProduct) => ({
            value: row.title,
            secondRowValue: `SKU: ${row.sellerSku}`,
            image: row.imageUrl,
            link: row.linkUrl,
            target: "_blank",
          }),
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isVisible: true,
          sticky: "left",
          colSpan: 2,
        },
        {
          Header: t("crossChannelProduct.totalSales"),
          id: "totalSales",
          accessor: (row: { totalSales: number }) => {
            return {
              value: formatCurrencyRounded(
                row.totalSales,
                currencyRates,
                "USD",
                currentCurrency
              ),
            };
          },
          Cell: ValueAndGrowthCell,
          align: "right",
          isVisible: true,
        },
        {
          Header: t("crossChannelProduct.totalQuantity"),
          id: "totalQuantity",
          accessor: "totalQuantity",
          isVisible: true,
          align: "right",
        },
      ],
      [currencyRates, currentCurrency, t]
    );

    const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        pageIndex,
        pageSize,
        sortKey: sortBy[0]?.id || "totalSales",
        sortOrder: sortBy[0]?.asc ? "asc" : "desc",
      });
    }, []);

    return (
      <Panel
        id="cross-channel-product-performance"
        title={t("crossChannelProduct.tableTitle")}
        content={
          <Table
            columns={columns}
            data={products}
            loading={isLoading}
            fetchData={fetchData}
            pagination
            pageCount={Math.ceil((totalCount || 0) / paginationParams.pageSize)}
            pageSize={paginationParams.pageSize}
          />
        }
      />
    );
  }
);

export default CrossChannelProductPerformance;

import {
  RESPONSE_CODE,
  isHttpResponseValid,
} from "../utils/httpsResponseCodes";

import { Dispatch } from "redux";
import { FORBIDDEN } from "http-status-codes";
import axios from "axios";
import { baseUrl } from "../../configs";
import { setError } from "../globalToast.redux";

axios.defaults.withCredentials = true;

export const connectMercadoLibre =
  (code: string, shopName: string, countryCode: string) =>
  (dispatch: Dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        const options = {
          method: "POST",
          url: `${baseUrl}/auth/mercadolibre/connect`,
          data: { code, shopName, countryCode },
        };
        const res = await axios(options);

        if (
          res &&
          isHttpResponseValid(res.status) &&
          res.data &&
          res.data.code === RESPONSE_CODE.SUCCESS
        ) {
          resolve("succeed");
        } else if (res.data.code === RESPONSE_CODE.INVALID_TOKEN) {
          setError(dispatch, res.data.message);
          reject("tokenInValid");
        } else {
          setError(dispatch, res.data.message);
          reject("failed");
        }
      } catch (err: any) {
        if (err.response && err.response.status === FORBIDDEN) {
          reject("forbidden");
        } else {
          setError(dispatch, err, err.response.status);
        }
      }
    });

import { Box, CircularProgress, Grid, styled } from "@material-ui/core";
import ColumnSelect, { Column } from "~/components/adTable/columnSelect";
import { CsvData, getProductCsvData } from "./productPopOutCsvUtils";
import { CurrentStore, Range } from "~/typedef/store";
import {
  ProductProfitabilityRow,
  SellInProductProfitabilityRow,
  SellOutProductProfitabilityRow,
  VendorChannelProfitAndLossResponse,
  useGetProductProfitabilityQuery,
  useUploadDirectCostFileMutation,
} from "~/store/mystore/vendorProfitability.redux";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  formatCurrency,
  getCurrencyByCountryCode,
  intFormatter,
} from "~/utils/currencyUtils";
import { get, isEmpty } from "lodash";

import ActionCell from "~/components/table/cells/actionCell";
import AdTable from "~/components/adTable/table";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import Bold from "~/components/typography/bold";
import { CSVLink } from "react-csv";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import DownloadCsvIcon from "~/icons/downloadCsvIcon";
import DrawerPanel from "~/components/drawerPanel/drawerPanel";
import EditCell from "~/components/table/cells/editCell";
import { LinkAndImageCell } from "~/components/table/cells/linkAndImageCell";
import { PaginationArgs } from "~/typedef/pagination";
import Panel from "@components/panel/panel";
import { ProfitAndLossPopOut } from "./profitAndLossPopOut";
import SearchFilter from "~/components/adTable/searchFilter";
import { SellType } from "~/pages/singleChannel/profitability/vendor/profitabilityProduct";
import SidePanel from "~/components/drawerPanel/sidePanel";
import SmallButton from "~/components/buttons/smallButton";
import StatusText from "~/components/typography/status";
import SyncDialog from "~/modules/profitLossProductTable/syncDialog";
import { TableCellProp } from "~/components/table/cellProps";
import TemplateDialog from "~/modules/widgets/vendorProfitability/templateDialog";
import { ValueCell } from "~/components/table/cells/valueCell";
import moment from "moment";
import picMissing from "../../../img/picMissing.jpg";
import styledComponents from "styled-components";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const Image = styledComponents.img`
  display: flex;
  height: calc(45px - 1rem);
  width: calc(45px - 1rem);
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  object-fit: cover;
  margin: 0 8px;
`;

const Title = styledComponents(Bold)`
  overflow: hidden;
  width: 80%;
`;

const SmallIcon = styledComponents(DownloadCsvIcon)`
  height: 18px;
  width: 18px;
  margin: -5px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const SidePanelHeader = ({
  title,
  image,
  description,
  csvData,
}: {
  title: string;
  image: string;
  description: string;
  csvData: CsvData;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="noWrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="noWrap"
        alignItems="center"
        flexGrow="1"
        flexShrink="1"
      >
        {image ? <Image src={image} /> : <Image src={picMissing} />}
        {title && (
          <Title variant="h5" title={title} noWrap>
            {title}
          </Title>
        )}
      </Box>

      <CSVLink
        style={{ marginLeft: "15px" }}
        data={csvData}
        filename={description}
      >
        <SmallIcon />
      </CSVLink>
    </Box>
  );
};

interface ProfitabilityProductProps {
  includeTax: boolean;
  currentCurrency: string;
  currentRange: Omit<Range, "priorToDate" | "priorFromDate">;
  store: CurrentStore;
  sellType: SellType;
  handleCogsEdit?: (product: ProductProfitabilityRow) => void;
  report?: boolean;
  title?: string;
}

const CenteredBox = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  padding: "24px",
}));

const ActionButton = styled(SmallButton)(() => ({
  marginRight: "1rem",
}));

const ProfitabilityProduct = memo<ProfitabilityProductProps>(
  function ProfitabilityProduct({
    includeTax,
    currentCurrency,
    currentRange,
    store,
    report,
    sellType,
    handleCogsEdit,
    title,
  }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedListing, setSelectedListing] = useState<
      ProductProfitabilityRow | undefined
    >();

    const { t } = useTranslation();
    const [uploadDirectCostFile, { isLoading }] =
      useUploadDirectCostFileMutation();

    const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
    const [cogsUploadDialogOpen, setCogsUploadDialogOpen] = useState(false);

    const homeCurrency = getCurrencyByCountryCode[store.marketplaceCountry];

    const currencyRates = useTypedSelector((state) =>
      get(state, "globalVar.currencyRates")
    );

    const currencyFormatter = useCallback(
      (value: number) =>
        isNaN(value) || value === 0.0
          ? "-"
          : isEmpty(currencyRates)
          ? `$${intFormatter.format(value)}` // only used in storybook
          : formatCurrency(value, currencyRates, homeCurrency, currentCurrency),
      [currencyRates, homeCurrency, currentCurrency]
    );

    const handleListingSelect = useCallback(
      (listing) => {
        setSelectedListing(listing);
        setDrawerOpen(true);
      },
      [setDrawerOpen]
    );

    const isSellOut = sellType === SellType.SELL_OUT;

    const columns = useMemo(
      () => [
        {
          Header: t("myStoresWidget.profitabilityProduct.productColumn"),
          accessor: ({
            title,
            imageUrl,
            linkUrl,
            asin,
          }: ProductProfitabilityRow) => ({
            value: title,
            secondRowValue: `ASIN: ${asin}`,
            image: imageUrl,
            link: linkUrl,
            target: "_blank",
          }),
          id: "title",
          Cell: (
            props: TableCellProp<{
              value: string;
              secondRowValue: string;
              image: string;
              link: string;
              target: string;
            }>
          ) => <LinkAndImageCell {...props} colorVariant="external" />,
          isLocked: true,
          isVisible: true,
          sticky: "left",
          /* This must be in sync - The text wont truncate until ~500px so 
           setting this lower than 500 creates a shifting effect on the table when sorting */
          width: 510,
          customWidth: 510,
        },
        ...(isSellOut
          ? [
              {
                Header: t(
                  "myStoresWidget.profitabilityProduct.shippedCogsColumn"
                ),
                accessor: (row: ProductProfitabilityRow) =>
                  currencyFormatter("shippedCogs" in row ? row.shippedCogs : 0),
                id: "shippedCogs",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
            ]
          : [
              {
                Header: t("myStoresWidget.profitabilityProduct.poValueColumn"),
                accessor: (row: ProductProfitabilityRow) =>
                  currencyFormatter("netReceipts" in row ? row.netReceipts : 0),
                id: "netReceipts",
                Cell: ValueCell,
                align: "right",
                isVisible: true,
              },
            ]),
        {
          Header: t(
            "myStoresWidget.profitabilityProduct.advertisingCostColumn"
          ),
          accessor: ({ advertisingCost }: ProductProfitabilityRow) =>
            currencyFormatter(advertisingCost),
          id: "advertisingCost",
          Cell: ValueCell,
          align: "right",
          isVisible: !report,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.deductions"),
          accessor: ({ deductions }: ProductProfitabilityRow) =>
            currencyFormatter(deductions),
          id: "deductions",
          Cell: ValueCell,
          align: "right",
          isVisible: !report,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.cogs"),
          accessor: ({ cogs }: ProductProfitabilityRow) =>
            currencyFormatter(cogs),
          id: "cogs",
          align: "right",
          Cell: (props: any) => (
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <ValueCell {...props} />
              </Grid>
              {handleCogsEdit && (
                <EditCell
                  {...props}
                  onEdit={({ value }) => handleCogsEdit(value)}
                />
              )}
            </Grid>
          ),
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.chargebacks"),
          accessor: ({ chargebacks }: ProductProfitabilityRow) =>
            currencyFormatter(chargebacks),
          id: "chargebacks",
          align: "right",
          Cell: ValueCell,
          isVisible: !report,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.expenses"),
          accessor: ({ expenses }: ProductProfitabilityRow) =>
            currencyFormatter(expenses),
          id: "expenses",
          align: "right",
          Cell: ValueCell,
          isVisible: !report,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.profit"),
          accessor: ({ profit }: ProductProfitabilityRow) => {
            return {
              value: currencyFormatter(profit),
              status: profit < 0 ? "error" : "success",
            };
          },
          id: "profit",
          align: "right",
          Cell: ({
            cell: {
              value: { value, status },
            },
          }: TableCellProp<{ value: string; status: string }>) => (
            <StatusText
              variant="body2"
              status={status}
              align="right"
              justify="flex-end"
            >
              {value === "-" ? value : `${value}`}
            </StatusText>
          ),
          isVisible: true,
        },
        {
          Header: t("myStoresWidget.profitabilityProduct.inventoryQuantity"),
          accessor: ({ inventoryQuantity }: ProductProfitabilityRow) =>
            inventoryQuantity,
          id: "inventoryQuantity",
          align: "right",
          Cell: ValueCell,
          isVisible: false,
        },
        ...(isSellOut
          ? [
              {
                Header: t("myStoresWidget.profitabilityProduct.unitsShipped"),
                accessor: (row: ProductProfitabilityRow) =>
                  "unitsShipped" in row ? row.unitsShipped : 0,
                id: "unitsShipped",
                align: "right",
                Cell: ValueCell,
                isVisible: !report,
              },
              {
                Header: t("myStoresWidget.profitabilityProduct.snsUnits"),
                accessor: (row: ProductProfitabilityRow) =>
                  "snsUnits" in row ? row.snsUnits : 0,
                id: "snsUnits",
                align: "right",
                Cell: ValueCell,
                isVisible: !report,
                disableSortBy: true,
              },
            ]
          : [
              {
                Header: t("myStoresWidget.profitabilityProduct.unitsReceived"),
                accessor: (row: ProductProfitabilityRow) =>
                  "receivedUnits" in row ? row.receivedUnits : 0,
                id: "receivedUnits",
                align: "right",
                Cell: ValueCell,
                isVisible: !report,
              },
            ]),
        {
          Header: t(`profitability.column.action`),
          id: "action",
          accessor: (row: ProductProfitabilityRow) => row,
          Cell: ({ value }: TableCellProp<ProductProfitabilityRow>) => {
            return (
              <ActionCell
                actions={[
                  {
                    icon: AssignmentOutlinedIcon,
                    action: () => handleListingSelect(value),
                  },
                ]}
              />
            );
          },
          align: "right",
          isLocked: true,
          isVisible: !report,
          disableSortBy: true,
        },
      ],
      [currentRange, currentCurrency, isSellOut]
    );
    const [searchText, setSearchText] = useState("");
    const [columnDef, setColumnDef] = useState<Column[]>(columns);

    const profitabilityProduct = selectedListing;

    // preformating data to match the interface VendorChannelProfitAndLossResponse for asin level popout
    const formattedDataForProductPopOut:
      | Omit<
          VendorChannelProfitAndLossResponse,
          "snsFromDate" | "snsToDate" | "currency"
        >
      | undefined = selectedListing
      ? {
          income: {
            ...(sellType === SellType.SELL_IN && {
              poValue:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .netReceipts || 0,
              submittedValue:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .submittedValue || 0,
              acceptedValue:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .acceptedValue || 0,
              cancelledValue:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .cancelledValue || 0,
              rejectedValue:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .rejectedValue || 0,
              shortageValue:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .shortageValue || 0,
            }),
            ...(sellType === SellType.SELL_OUT && {
              shippedCogs:
                (profitabilityProduct as SellOutProductProfitabilityRow)
                  .shippedCogs || 0,
            }),
          },
          expense: {
            adCost: profitabilityProduct?.advertisingCost || 0,
            cogs: { cogs: profitabilityProduct?.cogs || 0 },
            deduction: {
              total: profitabilityProduct?.deductions || 0,
            },
            chargeback: { total: profitabilityProduct?.chargebacks || 0 },
          },
          metrics: {
            ...(sellType === SellType.SELL_IN && {
              receivedUnits:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .receivedUnits || 0,
              submittedUnits:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .submittedUnits || 0,
              acceptedUnits:
                (profitabilityProduct as SellInProductProfitabilityRow)
                  .acceptedUnits || 0,
            }),
            ...(sellType === SellType.SELL_OUT && {
              shippedUnits:
                (profitabilityProduct as SellOutProductProfitabilityRow)
                  .unitsShipped || 0,
              snsUnits:
                (profitabilityProduct as SellOutProductProfitabilityRow)
                  .snsUnits || 0,
            }),
          },
        }
      : undefined;

    const { productCsvData, productCsvDescription } = getProductCsvData({
      currentRange,
      selectedListing,
      store,
      currentCurrency,
      formattedDataForProductPopOut,
      sellType,
      currencyRates,
      homeCurrency,
    });

    useEffect(() => {
      setColumnDef((columnDef) => {
        return columns.map((column) => {
          if (
            columnDef.find((each) => each.id === column.id)?.isVisible === false
          ) {
            return {
              ...column,
              isVisible: false,
            };
          } else {
            return column;
          }
        });
      });
    }, [columns]);

    const [paginationParams, setPaginationParams] = useState<PaginationArgs>({
      pageSize: 25,
      pageIndex: 0,
      sortKey: "shippedCogs",
      sortOrder: "desc",
    });

    // to handle case where the sort key selected is not appropriate for the sell type
    const getAppropriateSortKey = (
      sortKey: string,
      sortOrder: string,
      isSellOut: Boolean
    ): {
      sortKey: string;
      sortOrder: string;
    } => {
      if (isSellOut) {
        return ["netReceipts", "unitsReceived"].includes(sortKey)
          ? { sortKey: "shippedCogs", sortOrder: "desc" }
          : { sortKey, sortOrder };
      } else {
        return ["shippedCogs", "unitsShipped"].includes(sortKey)
          ? { sortKey: "netReceipts", sortOrder: "desc" }
          : { sortKey, sortOrder };
      }
    };

    const setPage = useCallback(({ pageSize, pageIndex, sortBy }) => {
      setPaginationParams({
        pageIndex,
        pageSize,
        ...(sortBy.length > 0
          ? {
              sortKey: sortBy[0].id,
              sortOrder: sortBy[0].desc ? "desc" : "asc",
            }
          : { sortKey: "shippedCogs", sortOrder: "desc" }),
      });
    }, []);

    const { products, loading } = useGetProductProfitabilityQuery(
      {
        mid: store.merchantId,
        marketplaceType: store.marketplace,
        marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
        includeTax,
        countryCode: store.marketplaceCountry,
        currentRange,
        paginationParams: {
          ...paginationParams,
          // final check for sort key
          ...getAppropriateSortKey(
            paginationParams.sortKey,
            paginationParams.sortOrder,
            isSellOut
          ),
        },
        searchText,
        sellType,
      },
      {
        selectFromResult: ({ data, isFetching }) => ({
          products: data || { rows: [], count: 0 },
          loading: isFetching,
        }),
      }
    );

    const { rows, count } = products;

    return (
      <>
        <DrawerPanel
          open={drawerOpen}
          setOpen={setDrawerOpen}
          containerId={"drawer-container"}
          sidePanel={
            selectedListing ? (
              <SidePanel
                displayImage
                headerComponent={
                  <SidePanelHeader
                    title={selectedListing.title}
                    image={selectedListing.imageUrl}
                    description={productCsvDescription}
                    csvData={productCsvData ?? []}
                  />
                }
                content={
                  <ProfitAndLossPopOut
                    sellType={sellType}
                    currentCurrency={currentCurrency}
                    currency={homeCurrency}
                    data={formattedDataForProductPopOut}
                  />
                }
                handleClose={() => setDrawerOpen(false)}
              />
            ) : (
              <Grid />
            )
          }
          mainPanel={
            <Panel
              id="vendor-product-profitability-table"
              title={
                title || t("myStoresWidget.vendor.productProfitability.title")
              }
              tooltip={t("myStoresWidget.vendor.productProfitability.tooltip")}
              content={
                !store ? (
                  <CenteredBox>
                    <CircularProgress />
                  </CenteredBox>
                ) : (
                  <AdTable
                    columns={columnDef}
                    data={rows}
                    fetchData={setPage}
                    loading={loading}
                    sorting={true}
                    pageSize={paginationParams.pageSize}
                    pageCount={Math.ceil(count / paginationParams.pageSize)}
                    pagination={true}
                    disableScroll={!!report}
                  />
                )
              }
              actions={
                !report ? (
                  <>
                    <ActionButton
                      style={{ marginRight: "1rem" }}
                      variant="outlined"
                      color="info"
                      onClick={() => setTemplateDialogOpen(true)}
                    >
                      {t("profitability.downloadButtonLabel")}
                    </ActionButton>
                    <ActionButton
                      variant="outlined"
                      color="info"
                      onClick={() => setCogsUploadDialogOpen(true)}
                    >
                      {t("profitability.uploadButtonLabel")}
                    </ActionButton>
                    <SearchFilter setSearchText={setSearchText} />
                    <ColumnSelect
                      columns={columnDef}
                      setColumns={(columns) => setColumnDef(columns)}
                    />
                    <DownloadCsv
                      mid={store.merchantId}
                      reportType="profitabilityProducts"
                      path="/api/generic/vendorProfitability/products"
                      params={{
                        mid: store.merchantId,
                        marketplaceType: store.marketplace,
                        marketplaceSubtype:
                          store.marketplaceSubtype || store.marketplace,
                        shopName: store.storeName,
                        countryCode: store.marketplaceCountry,
                        currentCurrency,
                        includeTax,
                        currentRange,
                        sellType,
                      }}
                    />
                  </>
                ) : undefined
              }
            />
          }
        />
        <TemplateDialog
          mid={store.merchantId}
          sellType={sellType}
          marketplaceType={store.marketplace}
          marketplaceSubtype={store.marketplaceSubtype || store.marketplace}
          open={templateDialogOpen}
          onClose={() => setTemplateDialogOpen(false)}
        />
        <SyncDialog
          open={cogsUploadDialogOpen}
          onClose={() => setCogsUploadDialogOpen(false)}
          onConfirm={(fromDate, file) => {
            uploadDirectCostFile({
              mid: store.merchantId,
              marketplaceType: store.marketplace,
              marketplaceSubtype: store.marketplaceSubtype || store.marketplace,
              countryCode: store.marketplaceCountry,
              fromDate: moment(fromDate).unix(),
              sellType,
              file,
              successMessage: t("vendorCogs.uploadSuccessMessage"),
            });
          }}
          isLoading={isLoading}
        />
      </>
    );
  }
);

export default ProfitabilityProduct;

import React, { memo, useState } from "react";
import { Switch, Tooltip, Typography } from "@material-ui/core";
import {
  getCurrencyByCountryCode,
  getExchangeRate,
} from "~/utils/currencyUtils";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DownloadCsv from "~/modules/reportDownload/downloadCsv";
import { InlineIconButton } from "~/icons/inlineIconButton";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import SalesByProductTable from "../widgets/salesByProduct";
import SearchFilter from "~/components/adTable/searchFilter";
import get from "lodash/get";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import useQueryParams from "~/hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";
import { useVendorStore } from "~/hooks/useVendorStore";

export const TYPE = {
  topBSR: "salesRank",
  topSellers: "totalSales",
  worstSellers: "lastSold",
  salesByProduct: "title",
};

interface SalesByProductProps {
  mid: string;
  marketplace: string;
  marketplaceSubtype: string;
  sellerId: string;
  countryCode: string;
  type: keyof typeof TYPE;
  condensed?: boolean;
}

const SalesByProduct = memo<SalesByProductProps>(function SalesByProduct({
  mid,
  marketplace,
  marketplaceSubtype,
  type,
  condensed,
  sellerId,
  countryCode,
}) {
  const { t } = useTranslation();
  const userInfo = useTypedSelector((state) => state.user);
  const store = useTypedSelector((state) =>
    get(state, "persistentAppSettings.setting.data.currentStore")
  );
  const includeTax = useTypedSelector((state) =>
    Boolean(state.persistentAppSettings?.setting?.data?.includeTax)
  );
  const urlSearchParams = useQueryParams();
  const { currentRange, currentPeriod, selectedTimezone } =
    useDateRangeFilters();
  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const currencyRates = useTypedSelector(
    (state) => state.globalVar.currencyRates
  );

  const isVendor = useVendorStore(mid);

  const [searchText, setSearchText] = useState("");
  const [includeNoSales, setIncludeNoSales] = useState(type === "worstSellers");
  const [includeNoInventory, setIncludeNoInventory] = useState(
    type === "topSellers" || isVendor
  );
  const groupId = urlSearchParams.get("groupId");

  const footerLink =
    condensed &&
    (!marketplace.includes("amazon_vendor") || userInfo.brandAnalyticsOn)
      ? {
          url: marketplaceLink(marketplace, mid, type.toLowerCase()),
          label: t("generic.viewAllLink"),
        }
      : undefined;

  const handleInventoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIncludeNoInventory(event.target.checked);
  };

  const handleSalesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeNoSales(event.target.checked);
  };

  const getCSVLabel = () => {
    if (isVendor) {
      if (type === "topSellers") {
        return "mostOrderedProcuts";
      }
      if (type === "worstSellers") {
        return "leastOrderedProcuts";
      }
    }
    return type;
  };

  if (store) {
    const exchangeRate = getExchangeRate(
      currencyRates,
      getCurrencyByCountryCode[store.marketplaceCountry],
      currentCurrency
    );

    return (
      <SalesByProductTable
        mid={mid}
        marketplace={marketplace}
        marketplaceSubtype={marketplaceSubtype}
        type={type}
        condensed={condensed}
        sellerId={sellerId}
        countryCode={countryCode}
        currentPeriod={currentPeriod}
        currentRange={currentRange}
        currentCurrency={currentCurrency}
        footerLink={footerLink}
        searchText={searchText}
        includeNoSales={includeNoSales}
        includeNoInventory={includeNoInventory}
        includeTax={includeTax}
        groupId={groupId}
        timezone={selectedTimezone}
        actions={
          <>
            {type !== "topSellers" && (
              <>
                <Typography variant="body2">
                  {t("myStoresWidget.salesByProduct.showNoSales")}
                </Typography>
                <Tooltip
                  title={
                    t(
                      "myStoresWidget.salesByProduct.showNoSalesTooltip"
                    ) as string
                  }
                >
                  <InlineIconButton />
                </Tooltip>
                <Switch
                  color="primary"
                  checked={includeNoSales}
                  onChange={handleSalesChange}
                />
                {!isVendor && (
                  <>
                    <Typography variant="body2">
                      {t("myStoresWidget.salesByProduct.showNoInventory")}
                    </Typography>
                    <Tooltip
                      title={
                        t(
                          "myStoresWidget.salesByProduct.showNoInventoryTooltip"
                        ) as string
                      }
                    >
                      <InlineIconButton />
                    </Tooltip>
                    <Switch
                      color="primary"
                      checked={includeNoInventory}
                      onChange={handleInventoryChange}
                    />
                  </>
                )}
              </>
            )}
            {!condensed && (
              <>
                <SearchFilter {...{ setSearchText }} />
                <DownloadCsv
                  {...{
                    reportType: "salesByProduct",
                    path: "/api/bff/salesByProduct",
                    mid: store.merchantId,
                    params: {
                      type,
                      shopName: store.storeName,
                      mid,
                      marketplace,
                      marketplaceSubtype,
                      sellerId,
                      countryCode,
                      ...currentRange,
                      sortKey: TYPE[type],
                      sortOrder: !["worstSellers", "topBSR"].includes(type)
                        ? "desc"
                        : "asc",
                      searchText,
                      includeNoSales,
                      includeNoInventory,
                      includeTax,
                      ...(Boolean(groupId) && { groupId }),
                      targetCurrency: currentCurrency,
                      exchangeRate,
                      label: getCSVLabel(),
                    },
                  }}
                />
              </>
            )}
          </>
        }
      />
    );
  }
  return <LoadingIndicator />;
});

export default SalesByProduct;

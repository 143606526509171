import {
  OutOfStockCell,
  calculateOutOfStockStatus,
} from "../../../components/table/cells/outOfStockCell";
import React, { memo, useCallback, useMemo } from "react";
import { getShopName, marketplaceLink } from "~/utils/marketplaceUtils";
import { useDispatch, useSelector } from "react-redux";

import { LinkCell } from "~/components/table/cells/linkCell";
import { MarketplaceAndCountryCell } from "../../../components/table/cells/marketplaceAndCountryCell";
import { Panel } from "../../../components/panel/panel.tsx";
import StatusIndicator from "../../../components/statusIndicator/statusIndicator";
import Table from "../../../components/table/table";
import { ValueCell } from "../../../components/table/cells/valueCell";
import { fetchInventoryStatus } from "../../../store/overview/inventoryStatus.redux";
import { getStatusTitle } from "~/utils/accountHealthUtils";
import { numberWithCommas } from "~/utils/utils";
import { useTranslation } from "react-i18next";

const formatMarketplaceData = (
  filteredStores,
  inventoryStatus,
  t,
  conditionalFormatting
) =>
  filteredStores &&
  inventoryStatus &&
  inventoryStatus.rows &&
  inventoryStatus.rows.map((storeInventoryStatus) => {
    if (storeInventoryStatus) {
      const outOfStockPercentage = parseFloat(
        storeInventoryStatus.outOfStockPercentage
      );
      const status = calculateOutOfStockStatus(outOfStockPercentage);
      const shopName = {
        value: getShopName(
          filteredStores,
          storeInventoryStatus.marketplace.market,
          storeInventoryStatus.store
        ),
        link: marketplaceLink(
          storeInventoryStatus.marketplace.market,
          storeInventoryStatus.store
        ),
      };
      return {
        market: storeInventoryStatus.marketplace,
        shopName,
        status: {
          value: getStatusTitle(status, t),
          status,
        },
        listings: numberWithCommas(storeInventoryStatus.activeListings),
        availability: numberWithCommas(storeInventoryStatus.availableListings),
        outOfStockPercentage: {
          outOfStockPercentage: outOfStockPercentage,
          conditionalFormatting: conditionalFormatting,
        },
      };
    }
  });

const InventorySummary = memo(
  ({
    pageSize,
    report,
    userInfo,
    currentFilter,
    filteredStores,
    conditionalFormatting,
    timezone,
  }) => {
    const { t } = useTranslation();
    const inventoryStatus = useSelector(
      (state) => state.overview.inventoryStatus
    );
    const dispatch = useDispatch();

    const dispatchFetchInventoryStatus = useCallback(
      ({ pageIndex, pageSize }) => {
        dispatch(
          fetchInventoryStatus(
            {
              user: { _id: userInfo._id },
              pageIndex,
              pageSize,
              filter: currentFilter,
              filteredStores,
              timezone,
            },
            inventoryStatus.params
          )
        );
      },
      [
        currentFilter,
        filteredStores,
        dispatch,
        userInfo,
        inventoryStatus.params,
      ]
    );

    const data = useMemo(() => {
      const formattedData = formatMarketplaceData(
        filteredStores,
        inventoryStatus,
        t,
        conditionalFormatting
      );
      return formattedData ? formattedData : [];
    }, [inventoryStatus, t, userInfo, filteredStores]);

    const columns = useMemo(
      () => [
        {
          id: "market",
          Header: t("dashboardWidget.inventoryStatus.marketplaceColumn"),
          accessor: "market",
          Cell: MarketplaceAndCountryCell,
        },
        {
          id: "shopName",
          Header: t("dashboardWidget.inventoryStatus.storeColumn"),
          accessor: "shopName",
          Cell: LinkCell,
        },
        {
          id: "status",
          Header: t("dashboardWidget.inventoryStatus.statusColumn"),
          accessor: "status",
          id: "status",
          Cell: ({ cell }) => (
            <StatusIndicator
              size="small"
              status={cell.value.status}
              statusText={cell.value.value}
              conditionalFormatting={conditionalFormatting}
            />
          ),
          hiddenDown: "md",
        },
        {
          id: "listings",
          Header: t("dashboardWidget.inventoryStatus.listingsColumn"),
          accessor: "listings",
          Cell: ValueCell,
          align: "right",
          hiddenDown: "sm",
        },
        {
          id: "availability",
          Header: t("dashboardWidget.inventoryStatus.availabilityColumn"),
          accessor: "availability",
          Cell: ValueCell,
          align: "right",
          hiddenDown: "sm",
        },
        {
          id: "outOfStockPercentage",
          Header: t(
            "dashboardWidget.inventoryStatus.outOfStockPercentageColumn"
          ),
          accessor: "outOfStockPercentage",
          id: "outOfStockPercentage",
          align: "right",
          Cell: OutOfStockCell,
        },
      ],
      []
    );

    return (
      <Panel
        id="widget-inventory-status"
        title={t("dashboardWidget.inventoryStatus.mainTitle")}
        tooltip={t("dashboardWidget.inventoryStatus.mainTooltip")}
        content={
          <Table
            columns={columns}
            data={data}
            fetchData={dispatchFetchInventoryStatus}
            pageSize={pageSize}
            pagination={!report}
            loading={inventoryStatus.fetching}
            params={inventoryStatus.params}
            gridLayoutColumns={6}
            pageCount={
              inventoryStatus ? Math.ceil(inventoryStatus.count / pageSize) : 1
            }
          />
        }
      />
    );
  }
);

export default InventorySummary;

import { Box, Grid } from "@material-ui/core";
import {
  CurrencyRate,
  OrderedRevenueTrendComparison,
  Range,
} from "~/typedef/store";
import React, {
  ReactChild,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { DATETIME_PERIODS } from "~/store/utils/dateTimeUtils";
import MultiBarChart from "../../components/charts/barChart/barChartMulti";
import MultiLineChartGeneric from "~/components/charts/multiLineChart/multiLineChartGeneric";
import Panel from "~/components/panel/panel";
import PanelLoading from "~/components/loadingIndicator/panelLoadingIndicator";
import { User } from "~/typedef/user";
import { fetchOrderedTrend } from "~/store/mystore/vendor.redux";
import { getConvertedValue } from "~/utils/currencyUtils";
import { marketplaceLink } from "~/utils/marketplaceUtils";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

export interface ChartOption {
  value: string;
  label: string;
}

export interface ChartDataRevenue {
  revenue: number;
  startTime: number; //unix timestamp
  glanceViews: number;
  units: number;
  [x: string]: number;
}

export type ChartDataForOrderedRevenue = ChartDataRevenue[];

export const CHART_TYPE = {
  Revenue: "revenue",
  GlanceViews: "glanceViews",
  Conversion: "unitsPerView",
};
const CHART_TYPE_LEGENDS_MAPPING = {
  [CHART_TYPE.Revenue]: "chartLegends.revenue",
  [CHART_TYPE.GlanceViews]: "chartLegend.glanceViews",
  [CHART_TYPE.Conversion]: "chartLegends.unitsPerView",
};

interface OrderedRevenueParams {
  userInfo: User;
  mid: string;
  currentPeriod: DATETIME_PERIODS;
  currentRange: Range;
  currentCurrency: string;
  chartType: ChartOption;
  actions?: ReactChild;
  report?: boolean;
  condensed?: boolean;
  timezone: string;
  isLineChart?: boolean;
}

const formatOrderedRevenueData = (
  orderedRevenueData: OrderedRevenueTrendComparison[] | undefined,
  currentCurrency: string,
  currency = "AUD",
  currencyRates: CurrencyRate[],
  chartType: ChartOption
) => {
  if (!orderedRevenueData) {
    return [];
  }
  const type = chartType?.value || "revenue";
  return orderedRevenueData.map((data: OrderedRevenueTrendComparison) => {
    switch (type) {
      case CHART_TYPE.Revenue:
        return {
          startTime: data.startTime,
          endTime: data.endTime,
          current: data.current?.[type]
            ? getConvertedValue(
                currencyRates,
                currency,
                currentCurrency,
                data.current[type]
              )
            : data.current?.[type],
          prior: data.prior?.[type]
            ? getConvertedValue(
                currencyRates,
                currency,
                currentCurrency,
                data.prior[type]
              )
            : data.prior?.[type],
        };
      case CHART_TYPE.Conversion:
        return {
          startTime: data.startTime,
          endTime: data.endTime,
          current:
            typeof data.current?.glanceViews === "number" &&
            typeof data.current?.units === "number"
              ? data.current.glanceViews !== 0
                ? (data.current.units / data.current.glanceViews) * 100
                : 0
              : undefined,
          prior:
            typeof data.prior?.glanceViews === "number" &&
            typeof data.prior?.units === "number"
              ? data.prior.glanceViews !== 0
                ? (data.prior.units / data.prior.glanceViews) * 100
                : 0
              : undefined,
        };
      default:
        return {
          startTime: data.startTime,
          endTime: data.endTime,
          current: data.current?.[type],
          prior: data.prior?.[type],
        };
    }
  });
};

const OrderedRevenueTrend = memo<OrderedRevenueParams>(
  function OrderedRevenueTrend({
    userInfo,
    mid,
    currentPeriod,
    currentRange,
    currentCurrency,
    chartType,
    actions,
    report,
    condensed,
    timezone,
    isLineChart,
  }) {
    const { t } = useTranslation();
    const { fromDate, interval, toDate } = currentRange;

    const orderedRevenueTrend = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.chart.data
    );

    const currency = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.chart.currency
    );

    const lastReportDate = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.chart.lastReportDate
    );

    const lastUpdatedAt = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.chart.lastUpdatedAt
    );

    const currencyRates = useTypedSelector((state) =>
      state.globalVar ? state.globalVar.currencyRates : []
    );

    const params = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.chart?.params || {}
    );
    const loading = useTypedSelector(
      (state) => state?.vendor?.orderedRevenue?.chart?.fetching || false
    );

    const dispatch = useDispatch();

    useEffect(() => {
      const fetchData = async () => {
        await dispatchFetchOrderedTrend();
      };
      fetchData();
    }, [currentRange, mid, userInfo]);

    const dispatchFetchOrderedTrend = useCallback(() => {
      dispatch(
        fetchOrderedTrend(
          {
            user: userInfo,
            currentRange,
            mid,
          },
          params
        )
      );
    }, [currentRange, mid, userInfo, currentCurrency]);

    const chartData = useMemo(() => {
      return formatOrderedRevenueData(
        orderedRevenueTrend,
        currentCurrency,
        currency,
        currencyRates,
        chartType
      );
    }, [
      currentRange,
      mid,
      userInfo,
      orderedRevenueTrend,
      currentCurrency,
      currency,
      currencyRates,
      chartType,
    ]);

    const footerLink =
      condensed && userInfo.brandAnalyticsOn && !report
        ? {
            url: marketplaceLink("amazon_vendor", mid, "orderedrevenue"),
            label: t("generic.viewAllLink"),
          }
        : undefined;

    return (
      <Panel
        id="widget-sales-performance"
        title={t("chartTitle.orderedRevenueTrend")}
        subtitle={
          lastReportDate && lastUpdatedAt
            ? `${t("retailAnalytics.manufacturingView")} - ${t(
                "retailAnalytics.updated",
                {
                  lastReportDate: moment(lastReportDate).format("ll"),
                  lastUpdatedAt: moment(lastUpdatedAt).format("ll"),
                }
              )}`
            : `${t("retailAnalytics.manufacturingView")}`
        }
        tooltip={t("orderedRevenueTrend.mainTooltip")}
        actions={actions}
        footerLink={footerLink}
        content={
          loading ? (
            <PanelLoading />
          ) : (
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {!isLineChart ? (
                    <MultiBarChart
                      {...{
                        chartData,
                        currentPeriod,
                        currentCurrency,
                        fromDate,
                        toDate,
                        interval,
                        report,
                        timezone,
                        valueTooltipKey: t(
                          CHART_TYPE_LEGENDS_MAPPING[
                            chartType?.value || "revenue"
                          ]
                        ),
                        notCurrency: [
                          CHART_TYPE.GlanceViews,
                          CHART_TYPE.Conversion,
                        ].includes(chartType?.value),
                        valueSuffix:
                          chartType?.value === CHART_TYPE.Conversion ? "%" : "",
                        toFixed:
                          chartType?.value === CHART_TYPE.Conversion
                            ? 2
                            : undefined,
                        ...(chartType?.value === CHART_TYPE.Conversion
                          ? {
                              percentageChangeFormatting: { isPptChange: true },
                            }
                          : null),
                      }}
                    />
                  ) : (
                    <MultiLineChartGeneric
                      {...{
                        chartData,
                        currentPeriod: currentPeriod,
                        currency: currentCurrency,
                        fromDate: fromDate,
                        toDate: toDate,
                        interval,
                        report,
                        timezone,
                        valueTooltipKey: t(
                          CHART_TYPE_LEGENDS_MAPPING[
                            chartType?.value || "revenue"
                          ]
                        ),
                        notCurrency: [
                          CHART_TYPE.GlanceViews,
                          CHART_TYPE.Conversion,
                        ].includes(chartType?.value),
                        valueSuffix:
                          chartType?.value === CHART_TYPE.Conversion ? "%" : "",
                        toFixed:
                          chartType?.value === CHART_TYPE.Conversion
                            ? 2
                            : undefined,
                        ...(chartType?.value === CHART_TYPE.Conversion
                          ? {
                              percentageChangeFormatting: { isPptChange: true },
                            }
                          : null),
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )
        }
      />
    );
  }
);

export default OrderedRevenueTrend;

import axios from "axios";
import { baseUrl } from "../configs";
import get from "lodash/get";
import { isHttpResponseValid } from "./utils/httpsResponseCodes";
import { setError } from "./globalToast.redux";

export const LOAD_BUYBOX_HISTORY_REQUEST = "LOAD_BUYBOX_HISTORY_REQUEST";
export const LOAD_BUYBOX_HISTORY_SUCCESS = "LOAD_BUYBOX_HISTORY_SUCCESS";
export const LOAD_BUYBOX_HISTORY_FAILURE = "LOAD_BUYBOX_HISTORY_FAILURE";
export const LOAD_BUYBOX_SNAPSHOT_REQUEST = "LOAD_BUYBOX_SNAPSHOT_REQUEST";
export const LOAD_BUYBOX_SNAPSHOT_SUCCESS = "LOAD_BUYBOX_SNAPSHOT_SUCCESS";
export const LOAD_BUYBOX_SNAPSHOT_FAILURE = "LOAD_BUYBOX_SNAPSHOT_FAILURE";
export const LOAD_BUYBOX_PRODUCTS_REQUEST = "LOAD_BUYBOX_PRODUCTS_REQUEST";
export const LOAD_BUYBOX_PRODUCTS_SUCCESS = "LOAD_BUYBOX_PRODUCTS_SUCCESS";
export const LOAD_BUYBOX_PRODUCTS_FAILURE = "LOAD_BUYBOX_PRODUCTS_FAILURE";

export const SUBMIT_SYNC_PRICE_REQUEST = "SUBMIT_SYNC_PRICE_REQUEST";
export const SUBMIT_SYNC_PRICE_SUCCESS = "SUBMIT_SYNC_PRICE_SUCCESS";
export const SUBMIT_SYNC_PRICE_FAILURE = "SUBMIT_SYNC_PRICE_FAILURE";
export const LOAD_SYNC_STATUS_REQUEST = "LOAD_SYNC_STATUS_REQUEST";
export const LOAD_SYNC_STATUS_SUCCESS = "LOAD_SYNC_STATUS_SUCCESS";
export const LOAD_SYNC_STATUS_FAILURE = "LOAD_SYNC_STATUS_FAILURE";

export const EDIT_BUYBOX_PRICE = "EDIT_BUYBOX_PRICE";
export const RESET_BUYBOX_PRICE = "RESET_BUYBOX_PRICE";
export const RESET_ALL_BUYBOX_PRICES = "RESET_ALL_BUYBOX_PRICES";

const initialState = {
  products: { data: [], count: 0 },
  syncPending: [],
  syncStatus: [],
};

export const buyBox = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BUYBOX_HISTORY_REQUEST:
      return { ...state, historyLoading: true };
    case LOAD_BUYBOX_HISTORY_SUCCESS:
      return { ...state, historyLoading: false, history: action.payload };
    case LOAD_BUYBOX_HISTORY_FAILURE:
      return { ...state, historyLoading: false, history: null };
    case LOAD_BUYBOX_SNAPSHOT_REQUEST:
      return { ...state, snapshotLoading: true };
    case LOAD_BUYBOX_SNAPSHOT_SUCCESS:
      return { ...state, snapshotLoading: false, snapshot: action.payload };
    case LOAD_BUYBOX_SNAPSHOT_FAILURE:
      return { ...state, snapshotLoading: false, snapshot: null };
    case LOAD_BUYBOX_PRODUCTS_REQUEST:
      return { ...state, productsLoading: true };
    case LOAD_BUYBOX_PRODUCTS_SUCCESS:
      return { ...state, productsLoading: false, products: action.payload };
    case LOAD_BUYBOX_PRODUCTS_FAILURE:
      return {
        ...state,
        productsLoading: false,
        products: { data: [], count: 0 },
      };

    case SUBMIT_SYNC_PRICE_REQUEST:
      return { ...state, syncLoading: true };
    case SUBMIT_SYNC_PRICE_SUCCESS:
      return { ...state, syncLoading: false, feedSubmissionId: action.payload };
    case SUBMIT_SYNC_PRICE_FAILURE:
      return { ...state, syncLoading: false, feedSubmissionId: null };

    case LOAD_SYNC_STATUS_REQUEST:
      return { ...state, syncStatusLoading: true };
    case LOAD_SYNC_STATUS_SUCCESS:
      return { ...state, syncStatusLoading: false, syncStatus: action.payload };
    case LOAD_SYNC_STATUS_FAILURE:
      return { ...state, syncStatusLoading: false, syncStatus: [] };

    case EDIT_BUYBOX_PRICE:
      const existingSyncEntry = state.syncPending.find(
        (p) => p.sellerSku === action.payload.sellerSku
      );

      return {
        ...state,
        syncPending: existingSyncEntry
          ? state.syncPending.map((p) =>
              p.sellerSku === action.payload.sellerSku ? action.payload : p
            )
          : [...state.syncPending, action.payload],
      };

    case RESET_BUYBOX_PRICE:
      return {
        ...state,
        syncPending: state.syncPending.filter(
          (p) => p.sellerSku !== action.payload.sellerSku
        ),
      };

    case RESET_ALL_BUYBOX_PRICES:
      return {
        ...state,
        syncPending: [],
      };

    default:
      return state;
  }
};

export const loadBuyBoxHistory =
  (customerId, amazonSellerId, countryCode, numDays) => async (dispatch) => {
    dispatch({ type: LOAD_BUYBOX_HISTORY_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/amazon-mws-service/api/buybox/history`,
        {
          params: {
            customerId,
            amazonSellerId,
            countryCode,
            numDays,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: LOAD_BUYBOX_HISTORY_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status, "buyBoxHistory");
      }
    } catch (err) {
      setError(dispatch, err.message, err.code, "buyBoxHistory");
    }

    return dispatch({
      type: LOAD_BUYBOX_HISTORY_FAILURE,
    });
  };

export const loadBuyBoxSnapshot =
  (customerId, amazonSellerId, countryCode) => async (dispatch) => {
    dispatch({ type: LOAD_BUYBOX_SNAPSHOT_REQUEST });
    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/amazon-buybox-service/api/buybox/current`,
        {
          params: {
            customerId,
            amazonSellerId,
            countryCode,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: LOAD_BUYBOX_SNAPSHOT_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status, "buyBoxSnapshot");
      }
    } catch (err) {
      setError(dispatch, err.message, err.code, "buyBoxSnapshot");
    }

    return dispatch({
      type: LOAD_BUYBOX_SNAPSHOT_FAILURE,
    });
  };

export const loadBuyBoxProducts =
  (customerId, amazonSellerId, countryCode, filter, paginationParams) =>
  async (dispatch) => {
    dispatch({ type: LOAD_BUYBOX_PRODUCTS_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/amazon-mws-service/api/buybox/products`,
        {
          params: {
            customerId,
            amazonSellerId,
            countryCode,
            filter,
            ...paginationParams,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: LOAD_BUYBOX_PRODUCTS_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status, "buyBoxProducts");
      }
    } catch (err) {
      setError(dispatch, err.message, err.code, "buyBoxProducts");
    }

    return dispatch({
      type: LOAD_BUYBOX_PRODUCTS_FAILURE,
    });
  };

export const submitSyncPrice =
  (customerId, amazonSellerId, countryCode, prices) => async (dispatch) => {
    dispatch({ type: SUBMIT_SYNC_PRICE_REQUEST });

    try {
      const { data, status } = await axios.put(
        `${baseUrl}/api/amazon-mws-service/api/buybox/prices`,
        {
          prices,
        },
        {
          params: {
            customerId,
            amazonSellerId,
            countryCode,
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: SUBMIT_SYNC_PRICE_SUCCESS,
          payload: get(
            data,
            "SubmitFeedResponse.SubmitFeedResult.FeedSubmissionInfo.FeedSubmissionId"
          ),
        });
      } else {
        setError(dispatch, data.errMsg, status, "buyBoxSync");
      }
    } catch (err) {
      setError(dispatch, err.message, err.code, "buyBoxSync");
    }

    return dispatch({
      type: SUBMIT_SYNC_PRICE_FAILURE,
    });
  };

export const loadSyncStatus =
  (customerId, amazonSellerId, countryCode) => async (dispatch) => {
    dispatch({ type: LOAD_SYNC_STATUS_REQUEST });

    try {
      const { data, status } = await axios.get(
        `${baseUrl}/api/amazon-mws-service/api/buybox/feeds`,
        {
          params: {
            customerId,
            amazonSellerId,
            countryCode,
            feedType: "_POST_PRODUCT_PRICING_DATA_",
          },
        }
      );

      if (isHttpResponseValid(status) && data) {
        return dispatch({
          type: LOAD_SYNC_STATUS_SUCCESS,
          payload: data,
        });
      } else {
        setError(dispatch, data.errMsg, status, "loadSyncStatus");
      }
    } catch (err) {
      setError(dispatch, err.message, err.code, "loadSyncStatus");
    }

    return dispatch({
      type: LOAD_SYNC_STATUS_FAILURE,
    });
  };

export const editBuyBoxPrice =
  (
    sellerSku,
    title,
    imageUrl,
    isFulfilledByAmazon,
    currencyCode,
    originalPrice,
    updatedPrice
  ) =>
  (dispatch) =>
    dispatch({
      type: EDIT_BUYBOX_PRICE,
      payload: {
        sellerSku,
        title,
        imageUrl,
        isFulfilledByAmazon,
        currencyCode,
        originalPrice,
        updatedPrice,
      },
    });

export const resetBuyBoxPrice = (sellerSku) => (dispatch) =>
  dispatch({
    type: RESET_BUYBOX_PRICE,
    payload: {
      sellerSku,
    },
  });

export const resetAllBuyBoxPrices = () => (dispatch) =>
  dispatch({
    type: RESET_ALL_BUYBOX_PRICES,
  });

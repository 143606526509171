import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import {
  Shop,
  useConnectTikTokShopMutation,
  useGetAuthorizedShopsMutation,
} from "~/store/connections/connectTikTokShop.redux";

import Icon from "@material-ui/core/Icon";
import Medium from "~/components/typography/medium";
import NativeSelect from "~/components/nativeSelect/nativeSelect";
import { RESPONSE_CODE } from "~/store/utils/httpsResponseCodes";
import RaisedButton from "~/components/buttons/raisedButton";
import crossLogo from "~/img/cross_large.png";
import styled from "styled-components";
import tickLogo from "~/img/tick_large.png";
import useTikTokShopAuthcodeAuth from "~/hooks/useTikTokShopAuthcodeAuth";
import { useTranslation } from "react-i18next";

const GridWrapper = styled.div`
  margin: 0 auto;
  max-width: 350px;
  padding-top: 1rem;
  width: 100%;
`;

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledChildren = styled.div`
  margin: auto;
`;

const ConnectionStationIcon = styled(Icon)`
  margin-right: 5px;
`;

interface TikTokShopConnectPageProps {
  onSuccess: () => void;
  setIsSubmitting: (bool: boolean) => void;
}

const TikTokShopConnectPage: React.FC<TikTokShopConnectPageProps> = ({
  onSuccess,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [isUsaCountry, setCountryType] = useState<boolean>(true);
  const [selectedShop, setSelectedShop] = useState<Shop | null>(null);

  const onAuthError = useCallback((error: string) => {
    setError(t(error));
  }, []);

  const [getAuthorizedShops, { data: shopsData }] =
    useGetAuthorizedShopsMutation({
      fixedCacheKey: "tiktokShopGetAuthorizedShops",
    });
  const shops = shopsData?.shops;

  const [connectTikTokShop, { isLoading, isSuccess, isError }] =
    useConnectTikTokShopMutation();

  const onAuthCode = useCallback(async (code: string) => {
    getAuthorizedShops({
      authCode: code,
    })
      .unwrap()
      .then(({ code, errMsg }) => {
        if (errMsg) {
          onAuthError(errMsg);
        } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
          onAuthError("connectWizard.connectionError");
        }
      })
      .catch((error) => {
        const errorMessage = error.message || "connectWizard.connectionError";
        onAuthError(errorMessage);
      });
  }, []);

  const onShopConnect = useCallback(() => {
    if (selectedShop) {
      connectTikTokShop({
        shop: selectedShop,
      })
        .unwrap()
        .then(({ errMsg, code }) => {
          if (errMsg) {
            onAuthError(errMsg);
          } else if (code === RESPONSE_CODE.INVALID_TOKEN) {
            onAuthError("connectWizard.connectionError");
          } else {
            onSuccess();
          }
        })
        .catch((error) => {
          const errorMessage = error.message || "connectWizard.connectionError";
          onAuthError(errorMessage);
        });
    }
  }, [selectedShop]);

  const triggerSignin = useTikTokShopAuthcodeAuth(
    isUsaCountry,
    onAuthCode,
    onAuthError
  );

  return (
    <GridWrapper id="tiktok-shop-connect-wrapper">
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Grid item>
          <Medium color="textSecondary">
            {t("connectWizard.tikTokShopLinkTitle")}
          </Medium>
        </Grid>
        {isLoading || isSuccess || isError || error ? (
          <Grid container direction="column" alignItems="center">
            {isLoading && <CircularProgress />}
            {(isSuccess || isError || error) && (
              <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <ConnectionStationIcon>
                  <img src={isSuccess ? tickLogo : crossLogo} alt="logo" />
                </ConnectionStationIcon>
                <Typography variant="body1" color="textPrimary" align="center">
                  {isSuccess
                    ? t("connectWizard.tiktokShopSuccessMessage")
                    : error || t("generic.generalError")}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={4}
            item
            xs={12}
          >
            {shops ? (
              !shops.length ? (
                <Grid item xs={12}>
                  <Medium>{t("connectWizard.tiktokNoShopsLabel")}</Medium>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  item
                  xs={12}
                >
                  <Grid item xs={12}>
                    <Medium>{t("connectWizard.tiktokShopLabel")}</Medium>
                  </Grid>
                  <Grid item xs={9}>
                    <NativeSelect
                      name="shop"
                      fullWidth
                      value={selectedShop?.id || ""}
                      onChange={(e) => {
                        const selected = shops.find(
                          (shop) => shop.id === e.target.value
                        );
                        setSelectedShop(selected || null);
                      }}
                      options={shops.map((shop) => ({
                        value: shop.id,
                        label: shop.name,
                      }))}
                    />
                  </Grid>
                </Grid>
              )
            ) : (
              <Grid container item xs={12}>
                <Grid item xs={9}>
                  <Medium>{t("connectWizard.tiktokCountryTypeLabel")}</Medium>
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <>
                        <Switch
                          size="small"
                          color={"secondary"}
                          checked={isUsaCountry}
                          onClick={() => setCountryType(!isUsaCountry)}
                        />
                      </>
                    }
                    label={
                      isUsaCountry
                        ? t("connectWizard.tiktokUSCountryTypeLabel")
                        : t("connectWizard.tiktokNonUSCountryTypeLabel")
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid item>
              {shops ? (
                <RaisedButton
                  color="primary"
                  onClick={onShopConnect}
                  disabled={!selectedShop}
                >
                  <StyledWrapper>
                    <StyledChildren>
                      {t("connectWizard.selectStoreButton")}
                    </StyledChildren>
                  </StyledWrapper>
                </RaisedButton>
              ) : (
                <RaisedButton color="primary" onClick={triggerSignin}>
                  <StyledWrapper>
                    <StyledChildren>
                      {t("connectWizard.connectButton")}
                    </StyledChildren>
                  </StyledWrapper>
                </RaisedButton>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </GridWrapper>
  );
};

export default TikTokShopConnectPage;

import React, { memo, useState } from "react";

import ChargebackUploadDialog from "~/modules/vendor/chargebackUploadModal";
import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import DeductionsSyncDialog from "~/modules/widgets/vendorProfitability/deductionsSyncDialog";
import { Grid } from "@material-ui/core";
import InfoAlert from "~/components/alert/infoAlert";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { SellType } from "./profitabilityProduct";
import { SellTypeToggle } from "~/modules/widgets/vendorProfitability/sellTypeToggle";
import SmallButton from "@components/buttons/smallButton";
import VendorProfitAndLoss from "~/modules/widgets/vendorProfitability/profitAndLoss";
import get from "lodash/get";
import { getCurrencyByCountryCode } from "mm-utils-frontend";
import moment from "moment-timezone";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useGetChargebackMetadataQuery } from "~/store/mystore/vendorProfitability.redux";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ProfitabilityVendorStore = memo(function ProfitabilityVendorStore() {
  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const savedSellType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorSellType ||
      SellType.SELL_OUT
  );
  const { t } = useTranslation();

  const { currentRange } = useDateRangeFilters();

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );

  const [chargebackUploadDialogOpen, setChargebackUploadDialogOpen] =
    useState(false);
  const [deductionUpdateDialogOpen, setDeductionUpdateDialogOpen] =
    useState(false);
  const [chargebackAlertOpen, setChargebackAlertOpen] = useState(true);
  const [sellType, setSellType] = useState(savedSellType);

  const { hasChargebacks, maxChargebackDate, minChargebackDate } =
    useGetChargebackMetadataQuery(
      {
        mid: currentStore?.merchantId || "",
        marketplaceFilters: {
          marketplaceType: currentStore?.marketplace || "",
          marketplaceSubtype: currentStore?.marketplaceSubtype || "",
        },
        currentRange,
      },
      {
        skip: !currentStore,
        selectFromResult: ({ data }) => {
          return {
            hasChargebacks: data ? data.hasChargebacks : true,
            maxChargebackDate: data?.maxChargebackDate,
            minChargebackDate: data?.minChargebackDate,
          };
        },
      }
    );

  const title =
    sellType === SellType.SELL_OUT
      ? t("nav.storeProfitSellOut")
      : t("nav.storeProfitSellIn");
  return (
    <PageBlock>
      {currentStore ? (
        <>
          <Grid container justifyContent="flex-end" alignItems="center">
            <SellTypeToggle sellType={sellType} setSellType={setSellType} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InfoAlert
                message={`${t(
                  "vendorProfitability.chargebackMissingAlertText"
                )} ${
                  minChargebackDate && maxChargebackDate
                    ? t("vendorProfitability.chargebackMissingAlertDateText", {
                        startDate:
                          moment(minChargebackDate).format("DD MMM, YYYY"),
                        endDate:
                          moment(maxChargebackDate).format("DD MMM, YYYY"),
                      })
                    : ""
                }`}
                typographyWeight="medium"
                isOpen={chargebackAlertOpen && !hasChargebacks}
                onClose={() => {
                  setChargebackAlertOpen(false);
                }}
                actions={
                  <SmallButton
                    onClick={() => {
                      setChargebackUploadDialogOpen(true);
                    }}
                    color="primary"
                    variant="contained"
                  >
                    {t("vendorProfitability.chargebackUploadTitle")}
                  </SmallButton>
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VendorProfitAndLoss
                title={title}
                store={currentStore}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                sellType={sellType}
                setChargebackUploadDialogOpen={setChargebackUploadDialogOpen}
                setDeductionUpdateDialogOpen={setDeductionUpdateDialogOpen}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VendorProfitAndLoss
                title={title}
                store={currentStore}
                currentRange={currentRange}
                currentCurrency={currentCurrency}
                sellType={sellType}
                isComparison
                setChargebackUploadDialogOpen={setChargebackUploadDialogOpen}
                setDeductionUpdateDialogOpen={setDeductionUpdateDialogOpen}
              />
            </Grid>
          </Grid>
          <ChargebackUploadDialog
            mid={currentStore.merchantId}
            marketplaceType={currentStore.marketplace}
            marketplaceSubtype={currentStore.marketplaceSubtype}
            countryCode={currentStore.marketplaceCountry}
            openModal={chargebackUploadDialogOpen}
            setOpenModal={setChargebackUploadDialogOpen}
            currentRange={currentRange}
          />
          <DeductionsSyncDialog
            store={currentStore}
            open={deductionUpdateDialogOpen}
            onClose={() => {
              setDeductionUpdateDialogOpen(false);
            }}
            timezone={currentRange.timezone}
            storeCurrency={
              getCurrencyByCountryCode[currentStore.marketplaceCountry]
            }
          />
        </>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default ProfitabilityVendorStore;

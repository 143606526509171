import React, { memo, useState } from "react";

import { DEFAULT_CURRENCY } from "~/store/persistentAppSettings.redux";
import { Grid } from "@material-ui/core";
import LoadingIndicator from "~/components/loadingIndicator/loadingIndicator";
import PageBlock from "~/components/containers/sideNavPageBlock";
import { SellType } from "./profitabilityProduct";
import { SellTypeToggle } from "~/modules/widgets/vendorProfitability/sellTypeToggle";
import VendorMonthlyProfitAndLoss from "~/modules/widgets/vendorProfitability/monthlyProfitAndLoss";
import get from "lodash/get";
import { useDateRangeFilters } from "~/hooks/useDateRangeFilters";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "~/hooks/useTypedSelector";

const ProfitabilityVendorMonthly = memo(function ProfitabilityVendorMonthly() {
  const currentStore = useTypedSelector(
    (state) => state.persistentAppSettings?.setting?.data?.currentStore
  );
  const { t } = useTranslation();

  const { currentRange } = useDateRangeFilters();

  const currentCurrency = useTypedSelector(
    (state) =>
      get(state, "persistentAppSettings.setting.data.currentCurrency") ||
      DEFAULT_CURRENCY
  );
  const savedSellType = useTypedSelector(
    (state) =>
      state.persistentAppSettings?.setting?.data?.vendorSellType ||
      SellType.SELL_OUT
  );
  const [sellType, setSellType] = useState(savedSellType);

  return (
    <PageBlock>
      {currentStore ? (
        <>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            style={{
              paddingBottom: "10px",
            }}
          >
            <SellTypeToggle sellType={sellType} setSellType={setSellType} />
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <VendorMonthlyProfitAndLoss
                title={t("profitability.monthlyProfitTitle")}
                currentCurrency={currentCurrency}
                store={currentStore}
                currentRange={currentRange}
                sellType={sellType}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <LoadingIndicator />
      )}
    </PageBlock>
  );
});

export default ProfitabilityVendorMonthly;

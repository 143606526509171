/* eslint-disable no-magic-numbers */
import { Container } from "@material-ui/core";
import styled from "styled-components";

interface PageBlockProps {
  $report?: boolean;
  $transparent?: boolean;
}

const PageBlock = styled(Container)<PageBlockProps>`
  max-width: ${({ $report }) => ($report ? "1000px" : "1600px")};
  padding-bottom: 1rem;
  font-family: "Marine", sans-serif;
  background: ${({ $report, $transparent, theme }) =>
    theme.palette.type === "dark" || $report || $transparent
      ? "transparent"
      : theme.palette.background.default};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

export default PageBlock;
